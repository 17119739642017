import React, { Component } from "react";
import image1 from "../../Images/image1.PNG";
import image2 from "../../Images/image2.PNG";
import image3 from "../../Images/image3.PNG";
import image4 from "../../Images/image4.png";
import "./gelerie.css";
import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";
import "./../../../node_modules/react-slick/dist/react-slick";
import Carousel from "bee-carousel";
import host from "../../config/api";
import MediaQuery from "react-responsive";

export class galerie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slider: [],
      index: 0
  }
   this.carouselWrapperRef = React.createRef()
    this.slider = [];
    this.calerieSlider = [];
    this.index = 0;
    this.indexEtatD = 0;
    // this.state = {slider:[], status:false};
  }

 /* componentDidUpdate() {
    // this.setState({slider : this.props.project.galerie});
  }*/

    prev() {
        setTimeout(() => {
            this.setState({index: this.state.index - 1 < 0 ? 0 : this.state.index - 1});
        }, 600)
    }

    next() {
        setTimeout(() => {
            this.setState({index: this.state.index + 1 > this.state.slider.length ? 0 : this.state.index + 1})
        }, 600)
    }

  componentDidMount() {
    //this.slider = [];
    this.state.slider = [];
  }

  setState(state, callback) {
    super.setState(state, callback);
}

  changeArray(type, index) {

    if (this.carouselWrapperRef.current) {
      const elems = Array.from(this.carouselWrapperRef.current.querySelectorAll('.carousel-item'));
      elems.filter((e, k) => k !== index).forEach(e => e.classList.remove('active'));
      const current = elems[index] || null;
      if (current) current.classList.add('active');
  }

    switch (type) {
      case "etat":
        if (this.props.project.etatDavencement) {
          this.slider = this.props.project.etatDavencement;
        } else {
          this.slider = [];
        }

        this.forceUpdate();
        break;
      case "plan":
        if (this.props.project.planOfMasse) {
          console.log('planofmasss',this.props.project.planOfMasse)
          this.slider = [this.props.project.planOfMasse];
        } else {
          this.slider = [];
        }

        this.forceUpdate();
        break;
      case "galerie":
        
        /*if (this.props.project.galerie) {
          this.calerieSlider = this.props.project.galerie;
        }
        this.index = index?index:0;

        this.forceUpdate();*/

        if (this.props.project.galerie) {
          this.setState({slider: this.props.project.galerie})
          // this.setState((state) => ({...state, slider: this.props.project.galerie}))
          // this.slider = this.props.project.galerie;
      }
        break;
      default:
        break;
    }
    this.setState({index})
  }
  render() {
    //this.setState({slider : this.props.project.galerie});

    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      slidesPerView: 4,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    };
    const params2 = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1.4,
      spaceBetween: 10,

    };

    if (this.props.project) {
      this.calerieSlider = this.props.project.galerie;
      return (
        <div>
          <MediaQuery maxDeviceWidth={790}>
            {/* mobile */}

            <div ref={this.carouselWrapperRef}>
                          <Carousel {...params2}>
                            {this.calerieSlider.map((image, index) => (
                              <div class="swiper-slide">
                                {" "}
                                <img
                                  src={host + "uploads/" + image}
                                  class="d-block w-100"
                                  alt={image}
                                  data-toggle="modal"
                                  data-target="#swiper-slide1"
                                  onClick={() => this.changeArray('galerie',index)}
                                />
                              </div>

                            ))}
                          </Carousel>
                          {/* ---- modal slider -- */}
                          <div
                            class="modal fade swiper-slider"
                            id="swiper-slide1"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          
                          >
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-body">
                                  {/* ---- slider -- */}
                                  <div
                                    id="carouselExampleControls"
                                    class="carousel slide"
                                    data-ride="carousel"
                                  >
                                    <div id="sliderMobile" class="carousel-inner">
                                      {this.state.slider.map((image, index) => {


                                        const cls =
                                        index === this.state.index
                                            ? "carousel-item active"
                                            : "carousel-item";
                                        return (
                                          <div class={cls}>
                                            <img
                                              class="d-block w-100"
                                              src={host + "uploads/" + image}
                                              alt={image}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <a
                                      class="carousel-control-prev"
                                      href="#carouselExampleControls"
                                      role="button"
                                      data-slide="prev"
                                      onClick={() => this.prev()}
                                    >
                                      <span
                                        class="carousel-control-prev-icon"
                                        aria-hidden="true"
                                      ></span>
                                      <span class="sr-only">Previous</span>
                                    </a>
                                    <a
                                      class="carousel-control-next"
                                      href="#carouselExampleControls"
                                      role="button"
                                      data-slide="next"
                                      onClick={() => this.next()}
                                    >
                                      <span
                                        class="carousel-control-next-icon"
                                        aria-hidden="true"
                                      ></span>
                                      <span class="sr-only">Next</span>
                                    </a>
                                  </div>
                                  {/* -------------- */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ---- fin modal slider -- */}


            </div>
            

             {/* ---- modal slider -- */}
             <div
              class="modal fade swiper-slider"
              id="swiper-slide2"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                    {/* ---- slider -- */}
                    <div
                      id="carouselExampleControls2"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      <div class="carousel-inner">
                            <div id="planofmasse" class="carousel-item active">
                              <img
                                class="d-block w-100"
                                src={host + "uploads/" + this.props.project.planOfMasse}
                                alt={this.props.project.planOfMasse}
                              />
                            </div>
                      </div>

                    </div>
                    {/* -------------- */}
                    
                  </div>
                </div>
              </div>
            </div>
            {/* ---- fin modal slider -- */}



           {/* ---- modal slider -- */}
                <div
                class="modal fade swiper-slider"
                id="swiper-slide3"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                      {/* ---- slider -- */}
                      <div
                        id="carouselExampleControls3"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div id="etat" class="carousel-inner">
                          {this.props.project.etatDavencement.map((image, index) => {


                            const cls =
                            index === this.indexEtatD
                            ? "carousel-item active"
                            : "carousel-item";
                            return (
                              <div class={cls}>
                                <img
                                  class="d-block w-100"
                                  src={host + "uploads/" + image}
                                  alt={image}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <a
                          class="carousel-control-prev"
                          href="#carouselExampleControls3"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          class="carousel-control-next"
                          href="#carouselExampleControls3"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                      {/* -------------- */}
                    </div>
                  </div>
                </div>
                </div>
                {/* ---- fin modal slider -- */}


            <br />
            <div class="text-center">
              <button
                class="btn"
                data-toggle="modal"
                data-target="#swiper-slide2"
              >
                PLAN DE MASSE
            </button>
             
             {
             this.props.project.reference !== "CH28473" && <button
                class="btn"
                style={{ marginLeft: "10px" }}
                data-toggle="modal"
                data-target="#swiper-slide3"
                onClick={() => this.changeArray('etat',null)}
              >
                ETAT D'AVANCEMENT
            </button>}
            </div>
          </MediaQuery>
          <MediaQuery minDeviceWidth={790}>

            <div ref={this.carouselWrapperRef}>
                  <Carousel {...params}>
                      {this.calerieSlider.map((image, index) => (
                        <div class="swiper-slide">
                          {" "}
                          <img
                            src={host + "uploads/" + image}
                            class="d-block w-100"
                            alt={image}
                            data-toggle="modal"
                            data-target="#swiper-slide1"
                            onClick={() => this.changeArray('galerie',index)}
                          />
                        </div>
                      ))}
                  </Carousel>
                  {/* ---- modal slider -- */}
                  <div
                    class="modal fade swiper-slider"
                    id="swiper-slide1"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true" 
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal-body">
                          {/* ---- slider -- */}
                          <div
                            id="carouselExampleControls"
                            class="carousel slide"
                            data-ride="carousel"
                          >
                            <div id="galerie" class="carousel-inner">
                              {this.state.slider.map((image, index) => {


                                const cls =
                                  index === this.state.index
                                    ? "carousel-item active"
                                    : "carousel-item";
                                return (
                                  <div class={cls}>
                                    <img
                                      class="d-block w-100"
                                      src={host + "uploads/" + image}
                                      alt={image}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                            <a
                              class="carousel-control-prev"
                              href="#carouselExampleControls"
                              role="button"
                              data-slide="prev"
                              onClick={() => this.prev()}
                            >
                              <span
                                class="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a
                              class="carousel-control-next"
                              href="#carouselExampleControls"
                              role="button"
                              data-slide="next"
                              onClick={() => this.next()}
                            >
                              <span
                                class="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                          {/* -------------- */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ---- fin modal slider -- */}


            </div>
                  
                        {/* ---- modal slider -- */}
                        <div
              class="modal fade swiper-slider"
              id="swiper-slide2"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                    {/* ---- slider -- */}
                    <div
                      id="carouselExampleControls2"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      <div class="carousel-inner">
                            <div id="planofmasse" class="carousel-item active">
                              <img
                                class="d-block w-100"
                                src={host + "uploads/" + this.props.project.planOfMasse}
                                alt={this.props.project.planOfMasse}
                              />
                            </div>
                      </div>

                    </div>
                    {/* -------------- */}
                    
                  </div>
                </div>
              </div>
            </div>
            {/* ---- fin modal slider -- */}



           {/* ---- modal slider -- */}
                <div
                class="modal fade swiper-slider"
                id="swiper-slide3"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                      {/* ---- slider -- */}
                      <div
                        id="carouselExampleControls3"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div id="etat" class="carousel-inner">
                          {this.props.project.etatDavencement.map((image, index) => {


                            const cls =
                            index === this.indexEtatD
                            ? "carousel-item active"
                            : "carousel-item";
                            return (
                              <div class={cls}>
                                <img
                                  class="d-block w-100"
                                  src={host + "uploads/" + image}
                                  alt={image}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <a
                          class="carousel-control-prev"
                          href="#carouselExampleControls3"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          class="carousel-control-next"
                          href="#carouselExampleControls3"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                      {/* -------------- */}
                    </div>
                  </div>
                </div>
                </div>
                {/* ---- fin modal slider -- */}


            <br />
            <div class="text-center">
              <button
                class="btn"
                data-toggle="modal"
                data-target="#swiper-slide2"
              >
                PLAN DE MASSE
            </button>
              {this.props.project.reference !== "CH28473" &&<button
                class="btn"
                style={{ marginLeft: "10px" }}
                data-toggle="modal"
                data-target="#swiper-slide3"
                onClick={() => this.changeArray('etat',null)}
              >
                ETAT D'AVANCEMENT
            </button>}
            </div>
          </MediaQuery>

        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default galerie;
