import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css';
import Contact from './components/websitelayout/Contact'
import ContactMobile from './components/contact/contactMobile'
import Section from './components/websitelayout/Section'
import Footer from './components/websitelayout/Footer'
import image from './Images/background.jpg'
import $ from 'jquery';
import MediaQuery from "react-responsive";
import EmailService from './services/email-service'
import { withRouter } from 'react-router-dom';

export class App extends Component {
  constructor(props) {
    super(props);
    this.emailService = new EmailService();


    this.emailService.getUtms(window.location.search).then(data => {
      if (data.data.utm_source !== null) {

        sessionStorage.setItem('utms', JSON.stringify(data.data))
      } else {
        sessionStorage.setItem('refer', document.referrer);
      }
    })
  }
  componentDidMount() {
    $(".close.contact-section").click(function () {
      $(".contact-popup div#form-sec").fadeOut();
      $(".contact-popup").addClass("hidden");
    });
  }

  render() {
    return (
      <div className="App" style={{ backgroundColor: "#fff" }}>
        <Router>
          <MediaQuery maxDeviceWidth={790}>
            {/* mobile */}
            <Section />
            <ContactMobile />
            <Footer />
          </MediaQuery>
          <MediaQuery minDeviceWidth={790}>
            {/* desktop */}
            <Section />
            <Contact />
            <Footer />
          </MediaQuery>
        </Router>
      </div>
    )
  }
}

export default App
