import React, { Component } from 'react';
import host from "../../config/api";
import MediaQuery from "react-responsive";
import Carousel from "bee-carousel";

export class Atouts extends Component {
  // project = JSON.parse(localStorage.getItem('project'));
  /* project = {};
  host = "";*/
  constructor(props) {
    super(props)
    this.project = {};
  }
  componentWillMount() {
    this.project = this.props.project;
  }

  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1.4,
      spaceBetween: 10,
    };
    
    this.project = this.props.project;
    if (this.props.project) {
      return (
        <div class="container">
           <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <Carousel {...params}>

              <div class="swiper-slide video">
              <div class="card" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.70), rgba(255, 255, 255, 0.70)),url(${host + 'uploads/' + this.project.nosAtout[0].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[0].icon} class="icon" alt={this.project.nosAtout[0].icon} />
                  <h3>{this.project.nosAtout[0].title}</h3></div>
                <p>{this.project.nosAtout[0].description}</p>
              </div>
              </div>
                  <div class="swiper-slide video">
                  <div class="card" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.70), rgba(255, 255, 255, 0.70)),url(${host + 'uploads/' + this.project.nosAtout[1].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[1].icon} class="icon" alt={this.project.nosAtout[1].icon} />
                  <h3>{this.project.nosAtout[1].title}</h3></div>
                <p>{this.project.nosAtout[1].description}</p>
              </div>
              </div>
                  <div class="swiper-slide video">
                  <div class="card" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.70), rgba(255, 255, 255, 0.70)),url(${host + 'uploads/' + this.project.nosAtout[2].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[2].icon} class="icon" alt={this.project.nosAtout[2].icon} />
                  <h3>{this.project.nosAtout[2].title}</h3></div>
                <p>{this.project.nosAtout[2].description}</p>
              </div>
              </div>
                  <div class="swiper-slide video">
                  <div class="card" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.70), rgba(255, 255, 255, 0.70)),url(${host + 'uploads/' + this.project.nosAtout[3].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[3].icon} class="icon" alt={this.project.nosAtout[3].icon} />
                  <h3>{this.project.nosAtout[3].title}</h3></div>
                <p>{this.project.nosAtout[3].description}</p>
              </div>
              </div>
              
            
          </Carousel>
          </MediaQuery>
          <MediaQuery minDeviceWidth={790}>
          <div class="row video">
            <div class="col-md-6">
              <div class="card" style={{ backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0.5)),url(${host + 'uploads/' + this.project.nosAtout[0].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[0].icon} class="icon" alt={this.project.nosAtout[0].icon} />
                  <h3>{this.project.nosAtout[0].title}</h3></div>
                <p>{this.project.nosAtout[0].description}</p>
              </div>
              <div class="card" style={{ backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0.5)),url(${host + 'uploads/' + this.project.nosAtout[1].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[1].icon} class="icon" alt={this.project.nosAtout[1].icon} />
                  <h3>{this.project.nosAtout[1].title}</h3></div>
                <p>{this.project.nosAtout[1].description}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card" style={{ backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0.5)),url(${host + 'uploads/' + this.project.nosAtout[2].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[2].icon} class="icon" alt={this.project.nosAtout[2].icon} />
                  <h3>{this.project.nosAtout[2].title}</h3></div>
                <p>{this.project.nosAtout[2].description}</p>
              </div>
              <div class="card" style={{ backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0.5)),url(${host + 'uploads/' + this.project.nosAtout[3].image})` }}>
                <div class="flex-items"><img src={host + 'uploads/' + this.project.nosAtout[3].icon} class="icon" alt={this.project.nosAtout[3].icon} />
                  <h3>{this.project.nosAtout[3].title}</h3></div>
                <p>{this.project.nosAtout[3].description}</p>
              </div>
            </div>
          </div>
          </MediaQuery>
        </div>
      )
    } else {
      return (
        <div></div>
      );
    }
  }
}

export default Atouts
