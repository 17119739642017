import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as Goto } from 'react-scroll'
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.css';
import logo from "../../Images/logochaabane.png";
import { faFacebook, faLinkedin, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import Contactpopup from './Contact-popup'
import $ from 'jquery';
import ProjectService from '../../services/project-service';

export class HeaderHome extends Component {
    constructor(props) {
        super(props);
        this.projectService = new ProjectService();
        this.state = { projects: [], status: false };
    }

    async componentWillMount() {

        await this.projectService.getAllProjects().then(data => {
            this.setState({ projects: data["data"].projects, status: true });
            //localStorage.setItem
        });

    }

    render() {
        return (
            <div>
                <nav id="Header" class="navbar navbar-expand-lg home-header">
                    <ul class="navbar-nav col-9" style={{paddingLeft: "20%" }}>
                        <li class="item">
                            <Link class="link" to="/">ACCUEIL</Link>
                        </li>
                        <li class="item">
                            <Link class="link" to="/apropos">A PROPOS</Link>
                        </li>
                        <li class="item">
                            <Link class="link" data-toggle="modal" data-target="#noprojet">NOS PROJETS</Link>
                        </li>
                        <li class="item">
                            <Link class="link" to="/actualites">ACTUALITÉS</Link>
                        </li>
                        <li class="item">
                            <Link class="link" to="/carriere">CARRIÈRE</Link>
                        </li>
                        <li class="item">
                            <Link class="link" to="/contact">CONTACT</Link>
                        </li>
                    </ul>
                    <ul class="navbar-nav col-4" >
                        <li class="nav-item info" style={{fontSize: "18px" ,fontWeight:"bold"}}>
                            <FontAwesomeIcon icon={faPhoneAlt} /> <a href="tel:+212522211717">+212 522 21 17 17</a>
                            
                        </li>
             <a class="social-media " target="_blank" href="https://fr-fr.facebook.com/Chaabaneimmobilier/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a class="social-media ml-1" target="_blank" href="https://www.linkedin.com/company/chaabane-immobilier">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a class="social-media ml-1" target="_blank" href="https://www.instagram.com/chaabane.immobilier/?hl=fr">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a class="social-media ml-1" target="_blank" href="https://www.youtube.com/channel/UCUFlcKD70nQZj4s4Ky0yHHQ">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
                   
                    </ul>

                </nav>
                <nav id="Header2" class="navbar navbar-expand-lg home-header">
                    <img src={logo} class="d-block" alt="..." />
                    <ul class="navbar-nav ml-auto">
                        <li class="item">
                            <span
                                class="contact-popup-btn"
                            >NOUS CONTACTER</span>
                        </li>
                    </ul>
                </nav>
                <div class="contact-popup hidden home-header">
                    <Contactpopup home="true" projects={this.state.projects} />
                </div>
            </div>

        )
    }
}

export default HeaderHome
