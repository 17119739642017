import React, { Component } from 'react'
import calendar from "../../Images/calendar.png";
import quality from "../../Images/quality.png";
import like from "../../Images/like.png";
import transparency from "../../Images/transparency.png";

export class engagementsMobile extends Component {
    render() {
        return (
            
        <div id="engagements-section" class="container animated animatedFadeInUp fadeInUp"> 
                <h1 style={{ textAlign: 'center'}}>NOS ENGAGEMENTS</h1>
                <br></br>  
                    <div class="row ">
                        <div class="col-6">
                            <div class="text-center"> 
                            <img src={calendar} class="d-block w-100" alt="..." />
                                <div class="title">
                                    <h3>ENGAGEMENT</h3>
                                </div>
                            </div>
                        </div>	 
                        <div class="col-6">
                            <div class="text-center">
                            <img src={quality} class="d-block w-100" alt="..." />
                                <div class="title">
                                    <h3>QUALITÉ</h3>
                                </div>
                            </div>
                        </div>	 
                        <div class="col-6">
                            <div class="text-center">
                            <img src={like} class="d-block w-100" alt="..." />
                                <div class="title">
                                    <h3>SATISFACTION</h3>
                                </div>
                            </div>
                        </div>	 
                        <div class="col-6">
                            <div class="text-center"> 
                            <img src={transparency} class="d-block w-100" alt="..." />
                                <div class="title">
                                    <h3>TRANSPARENCE</h3>
                                  </div>                                                                  
                            </div>
                        </div>                    
                    </div>		
        </div>    
        )
    }
}

export default engagementsMobile
