import React, { Component } from "react";
import image1 from "../../Images/image1.PNG";
import image2 from "../../Images/image2.PNG";
import image3 from "../../Images/image3.PNG";
import image4 from "../../Images/image4.png";
import "./gelerie.css";
import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";
import "./../../../node_modules/swiper/dist/css/swiper.min.css";
import "./../../../node_modules/swiper/dist/css/swiper.css";
import "./../../../node_modules/react-slick/dist/react-slick";
import Carousel from "bee-carousel";
import ProjectService from "../../services/project-service";
import host from "../../config/api";
import { Link } from 'react-router-dom';

export class Projets extends React.Component {
  constructor(props) {
    console.log('je suis la');

    super(props);
    this.projectService = new ProjectService();
    this.project = {};
    console.log('props', props);

  }
  componentWillMount() {

    this.project = this.props.projects;
    console.log('project ppp', this.project);

  }
  getRoute(route) {

    return "/" + route;
  }

  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      slidesPerView: 3,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    };
    // this.projects = this.state.projects;
    if (this.project) {
      return (
        <Carousel {...params}>
          {this.project.map(project =>
            <Link class="btn" to={this.getRoute(project.route)}>
              <div class="swiper-slide">
                {" "}
                <img
                  src={host + "uploads/" + project.cover}
                  class="d-block w-100"
                  alt={project.cover}
                />
                <h3>{project.title}</h3>
              </div>
            </Link>
          )}
        </Carousel>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Projets;
