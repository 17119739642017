import React, { Component } from "react";
import Logo from "../../Images/logochaabane.png";
import "./Contact.css";
import image from "../../Images/contact.jpg";
import host from "../../config/api";
import $ from "jquery";
// import Countries from "./frCountries";
import Projects from "./projects.json";
import EmailService from "./../../services/email-service";
import { withRouter } from "react-router-dom";
import CountriesService from "../../services/countries-service";
import Countries from "../websitelayout/frCountriesNew";
import { Dropdown } from "primereact/dropdown";

export class Contactpopup extends Component {
  constructor(props) {
    super(props);
    this.countriesService = new CountriesService();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      project: "",
      message: "",
      country: "Maroc",
      phonePrefix: "+212",
      phoneNumber: "",
      refer: "",
      projectRef: "",
      condition: false,
      messageError: "",
      Countries: [],
      selectedCountry: {},
      code: "",
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.handleRefer = this.handleRefer.bind(this);
    this.handleProject = this.handleProject.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    //this.handlePrefix = this.handlePrefix.bind(this);
    //this.handleCountry = this.handleCountry.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleCondition = this.handleCondition.bind(this);
    this.EmailService = new EmailService();
  }
  async componentDidMount() {
    $(".contact-popup div#form-sec").hide();
    $(".close.contact-section").click(function () {
      $(".contact-popup div#form-sec").fadeOut();
      $(".contact-popup").addClass("hidden");
    });
    $(".contact-popup-btn").click(function () {
      if ($(".contact-popup").hasClass("hidden")) {
        $(".contact-popup div#form-sec").fadeIn();
        $(".contact-popup").removeClass("hidden");
      } else {
        $(".contact-popup div#form-sec").fadeOut();
        $(".contact-popup").addClass("hidden");
      }
    });

    $(window).scroll(function () {
      var wintop = $(window).scrollTop(),
        docheight = $(document).height(),
        winheight = $(window).height();
      var scrolltrigger = 95;
      if ((wintop / (docheight - winheight)) * 100 > scrolltrigger) {
        if ($(".contact-popup").not(".hidden")) {
          $(".contact-popup div#form-sec").fadeOut();
          $(".contact-popup").addClass("hidden");
        }
      }
    });

    let arrayCountries = [];

    await this.countriesService.getIpAdress().then((code_) => {
      this.setState({
        code: code_,
      });
    });

    Countries.map((data) => {
      if (data.alpha2Code === this.state.code) {
        console.log("dooooone", this.state.code);
        this.setState({
          selectedCountry: {
            name: data.name,
            dial_code: "+" + data.CallCodes[0],
            code: data.alpha2Code,
            flag: data.flag,
          },
        });
      }
      const dataa = {
        name: data.name,
        dial_code: "+" + data.CallCodes[0],
        code: data.alpha2Code,
        flag: data.flag,
      };
      arrayCountries.push(dataa);
    });

    this.setState({ Countries: arrayCountries });

    if (this.props.project) {
      this.setState({ projectRef: this.props.project.reference });
    }
  }

  selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          {/* <img
            alt={option.name}
            src={option.flag}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option.code.toLowerCase()}`}
          /> */}
          <div>{" (" + option.dial_code + ")"}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        {/* <img
          alt={option.name}
          src={option.flag}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${option.code.toLowerCase()}`}
        /> */}
        <div>{option.name + " (" + option.dial_code + ")"}</div>
      </div>
    );
  };

  setSelectedCountry(value) {
    this.setState({ country: value.name });
    this.setState({ phonePrefix: value.dial_code });
    this.setState({
      selectedCountry: value,
    });
  }

  handleMessage(event) {
    this.setState({ message: event.target.value });
  }
  handleRefer(event) {
    this.setState({ refer: event.target.value });
  }
  handleProject(event) {
    this.setState({ projectRef: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phoneNumber: event.target.value });
  }
  handlePrefix(event) {
    this.setState({ phonePrefix: event.target.value });
  }
  handleCountry(event) {
    this.setState({ country: event.target.value });

    Countries.map((option) => {
      if (option.name === event.target.value) {
        this.setState({ phonePrefix: option.dial_code });
      }
    });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleLastName(event) {
    this.setState({ lastName: event.target.value });
  }
  handleFirstName(event) {
    this.setState({ firstName: event.target.value });
  }
  handleCondition(event) {
    this.setState({ condition: event.target.checked });
  }
  renderOption() {
    return Countries.map((option) =>
      option.name === "Maroc" ? (
        <option key={option.dial_code} selected value={option.name}>
          {option.name}
        </option>
      ) : (
        <option key={option.dial_code} value={option.name}>
          {option.name}
        </option>
      )
    );
  }
  getReference() {
    return this.props.project.reference;
  }
  renderOptionProject() {
    console.log("Projects", Projects);
    if (this.props.project) {
      return Projects.map((option) => (
        <option value={option.ref}>{option.title}</option>
      ));
    }
  }
  async submit(event) {
    event.preventDefault();

    if (!this.state.lastName || this.state.lastName === "") {
      this.setState({ messageError: "Veuillez saisir votre nom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      this.setState({ messageError: "Veuillez saisir votre prénom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.email || this.state.email === "") {
      this.setState({ messageError: "Veuillez saisir votre email" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({
        messageError: "Veuillez saisir une adresse email valide",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.country || this.state.country === "") {
      this.setState({ messageError: "Veuillez choisir votre pays" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.phonePrefix || this.state.phonePrefix === "") {
      this.setState({
        messageError: "Veuillez choisir l'indicateur de votre pays",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.phoneNumber || this.state.phoneNumber === "") {
      this.setState({ messageError: "Veuillez saisir un numéro de téléphone" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.projectRef || this.state.projectRef === "") {
      this.setState({ messageError: "Veuillez sélectionez un projet" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    } else {
      await Projects.map((project) => {
        if (project.ref === this.state.projectRef) {
          this.setState({ project: project.title });
        }
      });
    }

    if (!this.state.refer || this.state.refer === "") {
      this.setState({ messageError: "Comment vous nous avez connus ?" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.condition || this.state.condition === false) {
      this.setState({
        messageError:
          "Veuillez accepter les conditions générales d'utilisation",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    const infoProspect = {};
    infoProspect.state = this.state;
    infoProspect.state.Countries = undefined;
    infoProspect.state.code = undefined;
    infoProspect.state.selectedCountry = undefined;

    if (sessionStorage.getItem("utms")) {
      infoProspect.utms = JSON.parse(sessionStorage.getItem("utms"));
    }
    // else if (sessionStorage.getItem("refer")) {
    //   infoProspect.refer = sessionStorage.getItem("refer");
    // }

    localStorage.setItem("prospect", JSON.stringify(infoProspect));

    /* await this.EmailService.sendEmail(body).then(data => {
          console.log('email', data);
      });*/
    this.props.history.push("/remerciements");
  }
  render() {
    $(document).ready(
      function () {
        if ($("body").hasClass("magic-house")) {
          this.setState({ projectRef: "CH28469" });
        } else if ($("body").hasClass("garden-bay")) {
          this.setState({ projectRef: "CH28467" });
        } else if ($("body").hasClass("ricoflor-palmes")) {
          this.setState({ projectRef: "CH28470" });
        } else if ($("body").hasClass("the-sand-house")) {
          this.setState({ projectRef: "CH28471" });
        } else if ($("body").hasClass("blue-waters-house")) {
          this.setState({ projectRef: "CH28465" });
        } else if ($("body").hasClass("les-perles-de-bentriaa")) {
          this.setState({ projectRef: "CH28468" });
        } else if ($("body").hasClass("magic-house-beach")) {
          this.setState({ projectRef: "CH28472" });
        } else if ($("body").hasClass("_16eme-angle")) {
          this.setState({ projectRef: "CH28473" });
        }else if ($("body").hasClass("anfabloom")) {
          this.setState({ projectRef: "CH28474" });
        }else {
        }
      }.bind(this)
    );
    if (this.props.project) {
      return (
        <div id="Contact" style={{ backgroundImage: `url(${image})` }}>
          <div class="row align-items-center" id="contact-section">
            <div class="col" id="logo-sec">
              <img id="image" src={Logo} alt="" />
            </div>
            <div class="col" id="form-sec">
              <form>
                <button
                  type="button"
                  class="close contact-section"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p class="txt">
                  Pour être rappelé par un de nos conseillers, veuillez
                  renseigner les champs suivants:
                </p>

                <div class="row">
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control inpt"
                      id="nom"
                      placeholder="Nom"
                      value={this.state.lastName}
                      onChange={this.handleLastName}
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control inpt"
                      id="Prenom"
                      placeholder="Prénom"
                      value={this.state.firstName}
                      onChange={this.handleFirstName}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 ">
                    <input
                      type="email"
                      class="form-control inpt"
                      id="exampleInputEmail1"
                      placeholder="email@exemple.com"
                      value={this.state.email}
                      onChange={this.handleEmail}
                    />
                  </div>
                  <div class="col-md-6 col-12">
                    {/* <div class="row"> */}
                    {/* <div class="form-group col-md-4 col-4">
                        <select
                          id="inputCountry"
                          class="form-control inpt"
                          onChange={this.handleCountry}
                        >
                          {this.renderOption()}
                        </select>
                      </div>
                      <div class="form-group col-md-3 col-3">
                        <input
                          type="text"
                          class="form-control inpt"
                          id="inputPhoneCode"
                          placeholder="+212"
                          value={this.state.phonePrefix}
                        />
                      </div> */}
                    <div class="form-group d-flex">
                      <Dropdown
                        value={this.state.selectedCountry}
                        options={this.state.Countries}
                        onChange={(e) => {
                          this.setSelectedCountry(e.value);
                        }}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Select a Country"
                        className="popup-dropdown inpt"
                        valueTemplate={this.selectedCountryTemplate}
                        itemTemplate={this.countryOptionTemplate}
                      />
                      <input
                        type="number"
                        class="form-control inpt ml-2"
                        id="inputPhone"
                        placeholder="Tel*"
                        value={this.state.phoneNumber}
                        onChange={this.handlePhone}
                      />
                    </div>
                    {/* </div>{" "} */}
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6">
                    <select
                      // id="inputproject"
                      class="form-control inpt"
                      onChange={this.handleProject}
                      value={this.getReference()}
                    >
                      {this.renderOptionProject()}
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <select
                      id="inputCountry"
                      class="form-control inpt"
                      onChange={this.handleRefer}
                    >
                      <option value="">Comment vous nous avez connus ?*</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Bannière web">Bannière web</option>
                      <option value="Google">Google</option>
                      <option value="YouTube">YouTube</option>
                      <option value="Gmail">Gmail</option>
                      <option value="Mubawab">Mubawab</option>
                      <option value="Waze">Waze</option>
                      <option value="Bouche à oreille">Bouche à oreille</option>
                      <option value="Convention entreprise">
                        Convention entreprise
                      </option>
                      <option value="Passage bureau de vente">
                        Passage bureau de vente
                      </option>
                      <option value="Habillage véhicule">
                        Habillage véhicule
                      </option>
                      <option value="Affichage urbain ">
                        Affichage urbain{" "}
                      </option>
                      <option value="Brochure / flyer publicitaire">
                        Brochure / flyer publicitaire
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6">
                    <textarea
                      class="form-control inpt"
                      id="validationTextarea"
                      placeholder="Message"
                      value={this.state.message}
                      onChange={this.handleMessage}
                    ></textarea>
                  </div>
                </div>
                {this.state.messageError !== "" ? (
                  <div class="alert alert-danger">
                    <strong>{this.state.messageError}</strong>
                  </div>
                ) : (
                  <div></div>
                )}
                <div class="row align-items-end">
                  <div class="form-group col-md-6">
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    {/* <button id="btn" type="submit" class="col btn btn-primary" disabled={this.validateCheck()}>*/}
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input chkinpt"
                        id="exampleCheck1"
                        checked={this.state.condition}
                        onChange={this.handleCondition}
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        J'ai lu et j'accepte{" "}
                        <a
                          style={{ color: "#b5a282" }}
                          href="/conditions-generales"
                          target="_blank"
                        >
                          les conditions générales d'utilisation{" "}
                        </a>
                        d'utilisation notamment la mention relative à{" "}
                        <a
                          style={{ color: "#b5a282" }}
                          href="/politique-de-confidentialite"
                          target="_blank"
                        >
                          la protection des données personnelles
                        </a>
                        .
                      </label>
                      <br />
                      <br />
                      <p>
                        Conformément à la loi 09-08, vous disposez d'un droit
                        d'accès, de rectification et d'opposition au traitement
                        de vos données personnelles. Ce traitement à été notifié
                        à la CNDP au titre du récépissé n°...
                      </p>
                    </div>
                    <button
                      id="btn"
                      onClick={this.submit.bind(this)}
                      class="col btn btn-primary"
                    >
                      {" "}
                      Envoyer
                    </button>
                  </div>
                  {this.props.project ? (
                    <div class="forLogom-group col-md-6">
                      <img
                        src={host + "uploads/" + this.props.project.logoP}
                        class="d-block"
                        alt="..."
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {this.props.home ? (
                    <div class="forLogom-group col-md-6">
                      <img src={Logo} class="d-block" alt="..." />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="Contact" style={{ backgroundImage: `url(${image})` }}>
          <div class="row align-items-center" id="contact-section">
            <div class="col" id="logo-sec">
              <img id="image" src={Logo} alt="" />
            </div>
            <div class="col" id="form-sec">
              <form>
                <button
                  type="button"
                  class="close contact-section"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p class="txt">
                  Pour être rappelé par un de nos conseillers, veuillez
                  renseigner les champs suivants:
                </p>

                <div class="row">
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control inpt"
                      id="nom"
                      placeholder="Nom"
                      value={this.state.lastName}
                      onChange={this.handleLastName}
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control inpt"
                      id="Prenom"
                      placeholder="Prénom"
                      value={this.state.firstName}
                      onChange={this.handleFirstName}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 ">
                    <input
                      type="email"
                      class="form-control inpt"
                      id="exampleInputEmail1"
                      placeholder="email@exemple.com"
                      value={this.state.email}
                      onChange={this.handleEmail}
                    />
                  </div>
                  <div class="col-md-6 col-12">
                    {/* <div class="form-group col-md-4 col-4">
                        <select
                          id="inputCountry"
                          class="form-control inpt"
                          onChange={this.handleCountry}
                        >
                          {this.renderOption()}
                        </select>
                      </div>
                      <div class="form-group col-md-3 col-3">
                        <input
                          type="text"
                          class="form-control inpt"
                          id="inputPhoneCode"
                          placeholder="+212"
                          value={this.state.phonePrefix}
                        />
                      </div> */}
                    <div class="form-group d-flex">
                      <Dropdown
                        value={this.state.selectedCountry}
                        options={this.state.Countries}
                        onChange={(e) => {
                          this.setSelectedCountry(e.value);
                        }}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        className="popup-dropdown inpt"
                        placeholder="Select a Country"
                        valueTemplate={this.selectedCountryTemplate}
                        itemTemplate={this.countryOptionTemplate}
                      />
                      <input
                        type="number"
                        class="form-control inpt ml-2"
                        id="inputPhone"
                        placeholder="Tel*"
                        value={this.state.phoneNumber}
                        onChange={this.handlePhone}
                      />
                    </div>
                  </div>{" "}
                </div>

                <div class="row">
                  <div class="form-group col-md-6">
                    <select
                      id="inputproject"
                      class="form-control inpt"
                      onChange={this.handleProject}
                      value={this.state.projectRef}
                    >
                      <option value="">Veuillez choisir votre projet*</option>
                      <option value="CH28474">ANFA BLOOM</option>
                      <option value="CH28473">Le 16 eme Angle</option>
                      <option value="CH28471">The Sand House</option>
                      <option value="CH28465">Blue Waters House</option>
                      <option value="CH28472">Magic House Beach</option>
                      <option value="CH28468">Les Perles de Bentriaa II</option>
                      <option value="CH28469">Magic House</option>
                      <option value="CH28467">Garden Bay</option>
                      <option value="CH28470">Ricoflores Palm</option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <select
                      id="inputCountry"
                      class="form-control inpt"
                      onChange={this.handleRefer}
                    >
                      <option value="">Comment vous nous avez connus ?*</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Bannière web">Bannière web</option>
                      <option value="Google">Google</option>
                      <option value="YouTube">YouTube</option>
                      <option value="Gmail">Gmail</option>
                      <option value="Mubawab">Mubawab</option>
                      <option value="Parrainage client">
                        Parrainage client
                      </option>
                      <option value="Recommandé par autre">
                        Recommandé par autre
                      </option>
                      <option value="Bureau de vente">Bureau de vente</option>
                      <option value="Habillage véhicule">
                        Habillage véhicule
                      </option>
                      <option value="Brochure / flyer publicitaire">
                        Brochure / flyer publicitaire
                      </option>
                      <option value="Panneaux Publicitaires">
                        Panneaux Publicitaires
                      </option>
                      <option value="Palissade">Palissade</option>
                      <option value="Panneaux signalétiques">
                        Panneaux signalétiques
                      </option>
                      <option value="La Fondation Mohamed VI">
                        La Fondation Mohamed VI
                      </option>
                      <option value="Wana Corporate">Wana Corporate</option>
                      <option value="Influenceur/blogueur">
                        Influenceur/blogueur
                      </option>
                      <option value="Waze">Waze</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6">
                    <textarea
                      class="form-control inpt"
                      id="validationTextarea"
                      placeholder="Message"
                      value={this.state.message}
                      onChange={this.handleMessage}
                    ></textarea>
                    {this.state.messageError !== "" ? (
                      <div
                        style={{ marginTop: "10px" }}
                        class="alert alert-danger"
                      >
                        <strong>{this.state.messageError}</strong>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                <div class="row align-items-end">
                  <div class="form-group col-md-6">
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                    {/* <button id="btn" type="submit" class="col btn btn-primary" disabled={this.validateCheck()}>*/}
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input chkinpt"
                        id="exampleCheck1"
                        checked={this.state.condition}
                        onChange={this.handleCondition}
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        J'ai lu et j'accepte{" "}
                        <a
                          style={{ color: "#b5a282" }}
                          href="/conditions-generales"
                          target="_blank"
                        >
                          les conditions générales d'utilisation{" "}
                        </a>{" "}
                        notamment la mention relative à{" "}
                        <a
                          style={{ color: "#b5a282" }}
                          href="/politique-de-confidentialite"
                          target="_blank"
                        >
                          la protection des données personnelles
                        </a>
                        .
                      </label>
                      <br />
                      <br />
                      <p>
                        Conformément à la loi 09-08, vous disposez d'un droit
                        d'accès, de rectification et d'opposition au traitement
                        de vos données personnelles. Ce traitement à été notifié
                        à la CNDP au titre du récépissé n°...
                      </p>
                    </div>
                    <button
                      id="btn"
                      onClick={this.submit.bind(this)}
                      class="col btn btn-primary"
                    >
                      {" "}
                      Envoyer
                    </button>
                  </div>
                  {this.props.project ? (
                    <div class="forLogom-group col-md-6">
                      <img
                        src={host + "uploads/" + this.props.project.logoP}
                        class="d-block"
                        alt="..."
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {this.props.home ? (
                    <div class="forLogom-group col-md-6">
                      <img src={Logo} class="d-block" alt="..." />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Contactpopup);
