import React, { Component } from "react";
import Carousel from "bee-carousel";
import image1 from "../../Images/image1.PNG";
import image2 from "../../Images/image2.PNG";
import image3 from "../../Images/image3.PNG";
import image4 from "../../Images/image4.png";

import ActualityService from '../../services/actuality-service';
import host from "../../config/api";
import { Link } from "react-router-dom";

export class actuality extends Component {
  constructor(props) {
    super(props);
    this.actualityService = new ActualityService();
    this.state = { actualities: [], status: false };
  }
  async componentWillMount() {

    await this.actualityService.getActualities().then(data => {
      this.setState({ actualities: data["data"].actualites, status: true });
      //localStorage.setItem
    });

  }
  getRoute(route) {
    return "/" + route;
  }
  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      slidesPerView: 3,
      spaceBetween: 40
    };
    if (this.state.actualities && this.state.actualities.length > 0) {
    return (
      <div
        class="home-page"
        style={{ backgroundColor: "white", padding: "80px 10px" }}
      >
        <h1
          style={{
            textAlign: "center",
            color: "#0d0c0b ",
            margin: "0 auto 50px auto",
            borderColor: "#b7853e"
          }}
        >
          ACTUALITES
        </h1>
        <div class="container-fluid">
          <Carousel {...params}>
            {this.state.actualities ? this.state.actualities.map((actuality, index) => (
              <div class="swiper-slide" key={index}>
                {" "}
                <img src={host + 'uploads/' + actuality.image} class="d-block w-100" alt={actuality.image} />
                <div>
                  <p>{actuality.title}</p>
                  <Link class="btn" to={"/article/" + actuality.ref}>
                    Lire Plus
                </Link>
                </div>
              </div>)) : (<div></div>)}
          </Carousel>
        </div>
      </div>
    );
} else {
      return <div></div>;
    }
  }
}

export default actuality;
