import React, { Component } from "react";
import Projets from "../projet/projets";
import HeaderHome from '../websitelayout/Header-home';
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import SlideHomeMobile from "../acceuil/Slide-home-Mobile";
import MediaQuery from "react-responsive";
import "./index.css";
import ProjectService from "../../services/project-service";
import EmailService from "./../../services/email-service";
import $ from 'jquery';
import Noprojet from "../projet/nosprojet";

export class index extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectService();
    this.state = { projects: [], prospect: {}, condidat: false, status: false };
    this.EmailService = new EmailService();
  }

  goBack() {
    window.history.back();
  }
  componentDidMount() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
    $("body").removeClass();
    $("body").addClass("home-page merci");
    $('head').append("<script> gtag('event', 'conversion', {'send_to': 'AW - 660091748 / APITCOTa0ckBEOTm4LoC'}); </script>")
    $('head').append("<script> fbq('track', 'Lead'); </script>")

    $('#inputProjectFooter option[value=""]').prop('selected', true);
  }
  async componentWillMount() {

    await this.projectService.getAllProjects().then(data => {

      this.setState({ projects: data["data"].projects, status: true });
    });
    if (localStorage.getItem('prospect')) {
      this.setState({ prospect: JSON.parse(localStorage.getItem('prospect')) });
      await this.EmailService.sendEmail(this.state.prospect).then(data => {
        console.log('email', data);
      });
      localStorage.removeItem('prospect');
    } else if (localStorage.getItem('refer')) {
       await this.EmailService.sendEmail({refer: localStorage.getItem('refer')}).then(data => {
        console.log('email', data);
      });
    }
    if (localStorage.getItem('condidat')) {
      this.setState({ condidat: true });
      localStorage.removeItem('condidat');
    }
  }




  render() {

    return (

      <div>


        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
          <div class="section-padding remerciements" style={{ backgroundColor: "#fff" }}>
            <br />
            <div class="row"><div class="zone-title">{this.state.condidat? (<h2 class="title">Merci pour votre candidature</h2>):(<h2 class="title">NOUS VOUS REMERCIONS <br />
              DE VOTRE <br />
              PRISE DE CONTACT.</h2>)
            }
              <a class="btn">Retour</a>
            </div></div>

            <br />
          </div>
          <div class="mobile-slide">
            <SlideHomeMobile />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <HeaderHome />
          <Noprojet/>
          <div class="section-padding remerciements" style={{ backgroundColor: "#fff" }}>
            <br /> 
            <div class="row"><div class="zone-title">
            {this.state.condidat? (<h2 class="title">Merci pour votre candidature</h2>):(<h2 class="title">NOUS VOUS REMERCIONS <br />
              DE VOTRE <br />
              PRISE DE CONTACT.</h2>)
            }
              <a class="btn" onClick={() => this.goBack()}>Retour</a>
            </div></div>

            <br />
          </div>
          <div class="section-padding projets" style={{ backgroundColor: "#000000" }}>
            <div class="row"><div class="zone-title"><h2 class="title">Nos projets</h2></div></div>
            <br />
            {this.state.projects.length > 0 ? (
              <Projets projects={this.state.projects} />
            ) : (<div></div>)}
            <br />
            <br />
            <br />
          </div>
        </MediaQuery>




      </div>
    );
  }
}

export default index;
