import React, { Component } from "react";
import "./video.css";

export class Virtuelle extends Component {
  constructor(props) {
    super(props);

    this.project = {};
  }
  componentWillMount() {
    this.video = this.props.video;
  }
  render() {

    return (
      <div class="section section-padding">
        {" "}
        <div class="row">
          <div class="zone-title">
            <h2 class="title">Visite Virtuelle</h2>
          </div>
        </div>
        <div class="container">
          <div class="row video">
            <div class="col-md-4">
              <div class="all-centre">
                <h2 style={{ color: "#fff" }}>
                  <span>Vivez l'expérience</span>
                  <br /> En faisant une <br />
                  <span>Visite Virtuelle!</span>
                </h2>
              </div>
            </div>
            <div class="col-md-8">
              <iframe
                width="560"
                height="315"
                src="https://virtuelle.chaabaneimmobilier.com/"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Virtuelle;
