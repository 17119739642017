import React, { Component } from "react";
import Galerie from "../galerie";
import Atouts from "../atouts";
import Projets from "../projets";
import Localisation from "../localisation";
import Acceuil from "../acceuil";
import Plans from "./plan";
import Header from "../../websitelayout/Header";
import HeaderMobile from "../../websitelayout/HeaderMobile";
import Description from "../decription";

import Virtuelle from "./virtuelle";
import Video from "../video";
import "./index.css";
import $ from "jquery";
import SlideHomeMobile from "../../acceuil/Slide-home-Mobile";
import MediaQuery from "react-responsive";
import ProjectService from "../../../services/project-service";
import Noprojet from "../nosprojet";

export class index extends Component {
  constructor(props) {
    super(props);
    this.video = "./video/";
    this.projectService = new ProjectService();
    this.state = { projet: {}, projects: [], ref: "CH28472", status: false };
  }
  componentDidMount() {
    $(document).ready(function () {
      $(".modal-backdrop").remove();
      $("body").removeClass();
      $("body").addClass("magic-house-beach");
      $(window).scrollTop(0);
      $('#inputProjectFooter option[value="CH28472"]').prop("selected", true);
    });

    $(".close.contact-section").click(function () {
      $(".contact-popup div#form-sec").fadeOut();
      $(".contact-popup").addClass("hidden");
    });
  }
  async componentWillMount() {
    let ref = "CH28472";

    /*await this.projectService.getProjectInfos(ref).then(data => {
      this.setState({ project: data["data"].project, status: true });
      //localStorage.setItem
    });*/
    let planOfMasse = [];
    await this.projectService.getProjectInfos(ref).then((data) => {
      planOfMasse.push(data["data"].project.planOfMasse);
      this.setState({ project: data["data"].project, status: true });
      this.setState({
        project: { ...this.state.project, planOfMasse: planOfMasse },
      });
      //localStorage.setItem
    });

    await this.projectService.getProjects(ref).then((data) => {
      this.setState({ projects: data["data"].projects, status: true });
      //localStorage.setItem
    });
  }

  render() {
    // const project = this.state.project;
    $("body").removeAttr("style");

    return (
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderMobile project={this.state.project} />
          <div class="mobile-hero">{/* <Menu />  */}</div>
          <div
            class="container-fluid"
            style={{ backgroundColor: "white", padding: 0 }}
          >
            <Acceuil project={this.state.project} />
          </div>

          <div
            class="section-padding desc"
            style={{ backgroundColor: "white" }}
          >
            <br />
            <Description project={this.state.project} />
            <br />
          </div>
          {this.state.project ? (
            <div
              class="section-padding Galerie"
              style={{ backgroundColor: "grey" }}
            >
              <div class="row">
                <div class="zone-title">
                  <h2 class="title">Galerie</h2>
                </div>
              </div>
              <br />
              <Galerie project={this.state.project} />
              <br />
              <br />
            </div>
          ) : (
            <div></div>
          )}
          <div
            class="section-padding atouts"
            style={{ backgroundColor: "#fff" }}
          >
            <br />
            <div class="row">
              <div class="zone-title">
                <h2 class="title">Nos Atouts</h2>
              </div>
            </div>
            <Atouts project={this.state.project} />
            <br />
          </div>

          {this.state.project ? (
            <div class="video" style={{ backgroundColor: "white" }}>
              <Video project={this.state.project} />
            </div>
          ) : (
            <div></div>
          )}

          {this.state.project ? (
            <div
              class="plans section-padding"
              style={{
                // backgroundImage: `url(${image})`,
                backgroundColor: "white",
              }}
            >
              <div class="row">
                <div class="zone-title">
                  <h2 class="title">NOS PLANS</h2>
                </div>
              </div>
              <Plans project={this.state.project} />
            </div>
          ) : (
            <div></div>
          )}

          <div class="virtu" style={{ backgroundColor: "#fff" }}>
            <Virtuelle video={this.state.video} />
          </div>
          <div
            class="section-padding maps"
            style={{ backgroundColor: "#facd89" }}
          >
            <br />
            <div class="row">
              <div class="zone-title">
                <h2 class="title">NOUS SOMMES ICI</h2>
              </div>
            </div>
            <Localisation project={this.state.project} />
            <br />
          </div>
          {this.state.project ? (
            <div class="mobile-slide">
              <div
                class="section-padding projets"
                style={{ backgroundColor: "#fff" }}
              >
                <div class="row">
                  <div class="zone-title">
                    <h2 class="title">Nos projets</h2>
                  </div>
                </div>
              </div>
              <SlideHomeMobile projects={this.state.project.nosProjets} />
            </div>
          ) : (
            <div></div>
          )}
        </MediaQuery>

        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Header project={this.state.project} projects={this.state.projects} />
          <Noprojet />
          <div
            class="container-fluid"
            style={{ backgroundColor: "white", padding: 0 }}
          >
            <Acceuil project={this.state.project} />
          </div>

          <div class="section-padding" style={{ backgroundColor: "white" }}>
            <br />
            <Description project={this.state.project} />
            <br />
          </div>

          {this.state.project ? (
            <div
              class="section-padding Galerie"
              style={{ backgroundColor: "grey" }}
            >
              <div class="row">
                <div class="zone-title">
                  <h2 class="title">Galerie</h2>
                </div>
              </div>
              <br />
              <Galerie project={this.state.project} />
              <br />
              <br />
            </div>
          ) : (
            <div></div>
          )}

          <div
            class="section-padding atouts"
            style={{ backgroundColor: "#fff" }}
          >
            <br />
            <div class="row">
              <div class="zone-title">
                <h2 class="title">Nos Atouts</h2>
              </div>
            </div>
            <Atouts project={this.state.project} />
            <br />
          </div>

          {this.state.project ? (
            <div class="video" style={{ backgroundColor: "#facd89" }}>
              <Video project={this.state.project} />
            </div>
          ) : (
            <div></div>
          )}

          {this.state.project ? (
            <div
              class="plans section-padding"
              style={{
                // backgroundImage: `url(${image})`,
                backgroundColor: "white",
              }}
            >
              <div class="row">
                <div class="zone-title">
                  <h2 class="title">NOS PLANS</h2>
                </div>
              </div>
              <Plans project={this.state.project} />
            </div>
          ) : (
            <div></div>
          )}
          {/* <div class="video" style={{ backgroundColor: "red" }}>
            <Video project={this.state.project} />
          </div> */}
          <div class="virtu" style={{ backgroundColor: "#facd89" }}>
            <Virtuelle video={this.state.video} />
          </div>
          <div
            class="section-padding maps"
            style={{ backgroundColor: "white" }}
          >
            <br />
            <div class="row">
              <div class="zone-title">
                <h2 class="title">NOUS SOMMES ICI</h2>
              </div>
            </div>
            <Localisation project={this.state.project} />
            <br />
          </div>

          {this.state.project ? (
            <div
              class="section-padding projets"
              style={{ backgroundColor: "#facd89" }}
            >
              <div class="row">
                <div class="zone-title">
                  <h2 class="title">Nos projets </h2>
                </div>
              </div>
              <br />
              <Projets projects={this.state.project.nosProjets} />

              <br />
              <br />
              <br />
            </div>
          ) : (
            <div></div>
          )}
        </MediaQuery>
      </div>
    );
  }
}

export default index;
