import React, { Component } from "react";
import Menu from "./menu";
import SlideHome from "./Slide-home";
import SlideHomeMobile from "./Slide-home-Mobile";
import EngagementsMobile from "../apropos/engagementsMobile";
import Engagement from "../apropos/engagements";
import Noprojet from "../projet/nosprojet";
import Actuality from "./actuality";
import ActualityMobile from "./actualityMobile";
import HeaderHome from "../websitelayout/Header-home";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import $ from "jquery";
import MediaQuery from "react-responsive";
import Engagementimage from '../../Images/background.jpg';


//import ProjectService from "../../services/project-service";

export class index extends Component {
  /*constructor(props) {

    super(props);
   // this.projectService = new ProjectService();
    this.state = { projects: [], status: false };
    this.projects = {};
  }
  async componentWillMount() {

    await this.projectService.getAllProjects().then(data => {
      this.setState({ projects: data["data"].projects, status: true });
      //localStorage.setIte
    });
  }*/
  componentDidMount() {
    $("body").removeClass();
    $("body").addClass("home-page");
    $('#inputProjectFooter option[value=""]').prop('selected', true);
  }

  render() {
   // if(this.state.projects.length > 0 ) {
    return (
      
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
          <div class="mobile-hero">
            <Menu />
          </div>
          
          <div class="mobile-slide">
            <SlideHomeMobile source="home" />
          </div>
          <div class="mobile-engagements" style={{backgroundImage: `linear-gradient(rgba(151, 122, 60, 0.52), rgba(151, 122, 60, 0.45)),
           url(${Engagementimage})`,backgroundSize: "cover",backgroundAttachment: "fixed"}}>
            <EngagementsMobile />
          </div>
          <div class="mobile-actualites">
            <ActualityMobile />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <HeaderHome />
          <Noprojet/>
          <div>
            <Menu />
          </div>
          <div>
            <SlideHome />
          </div>
          <div style={{backgroundImage: `linear-gradient(rgba(151, 122, 60, 0.52), rgba(151, 122, 60, 0.45)),
           url(${Engagementimage})`,backgroundSize: "cover",backgroundAttachment: "fixed"}}>
            <Engagement />
          </div>
          <div class="actualites">
            <Actuality />
          </div>
        </MediaQuery>
      </div>
    );/*} else {
      return(<div></div>)
    }*/
  }
}

export default index;
