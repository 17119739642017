import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Acceuil from "../acceuil";
import Contact from "../contact";
import Remerciements from "../remerciements";
import Apropos from "../apropos";
import Project from "../projet";
import Carriere from "../carriere";
import Magichouse from "../projet/magic-house";
import Magichousebeach from "../projet/magic-house-beach";
import Ricoflorpalmes from "../projet/ricoflor-palmes";
import Thesandhouse from "../projet/the-sand-house";
import Bluewaterhouse from "../projet/blue-water-house";
import Gardenbay from "../projet/garden-bay";
import Lesperlesdebentriaa from "../projet/les-perles-de-bentriaa";
import _16eme_angle from "../projet/16eme-angle";
import anfabloom from "../projet/AnfaBloom";
import Actualites from "../actualites";
import SingleActualite from "../single-actualite";
import ConditionGenerales from "../conditions-generales";
import PolitiqueDeConfidentialite from "../politique-de-confidentialite";

export class Section extends Component {
  render() {
    return (
      <Switch>
        {/* <Route path="/Project">
                <Project />
            </Route>
            <Route path="/magic-house">
                <Magichouse />
            </Route> */}
        <Route path="/Project" component={Project}>
          <Route path="/Project/magic-house" component={Magichouse} />
          <Route
            path="/Project/magic-house-beach"
            component={Magichousebeach}
          />
          <Route path="/Project/ricoflores-palm" component={Ricoflorpalmes} />
          <Route path="/Project/the-sand-house" component={Thesandhouse} />
          <Route path="/Project/blue-waters-house" component={Bluewaterhouse} />
          <Route path="/Project/garden-bay" component={Gardenbay} />
          <Route
            path="/Project/les-perles-de-bentriaa"
            component={Lesperlesdebentriaa}
          />
          <Route
            path="/Project/16eme-angle"
            component={_16eme_angle}
          />
          <Route
            path="/Project/AnfaBloom"
            component={anfabloom}
          />
        </Route>

        <Route path="/Apropos">
          <Apropos />
        </Route>
        <Route path="/contact" component={Contact} exact />

        <Route path="/remerciements">
          <Remerciements />
        </Route>
        <Route path="/carriere">
          <Carriere />
        </Route>
        <Route path="/actualites">
          <Actualites />
        </Route>
        <Route path="/conditions-generales">
          <ConditionGenerales />
        </Route>
        <Route path="/politique-de-confidentialite">
          <PolitiqueDeConfidentialite />
        </Route>
        {/*}<Route exact path="/article/:ref">
          <SingleActualite />
          </Route>{*/}
        <Route path="/article/:ref" component={SingleActualite} exact />
        <Route path="/">
          <Acceuil />
        </Route>
      </Switch>
    );
  }
}

export default Section;
