import React, { Component } from "react";
import first from "../../Images/silde1.jpg";
import { thisExpression } from "@babel/types";
import host  from '../../config/api';

export class acceuil extends Component {
  constructor(props) {
    super(props);
    this.project = {};
  }
  componentWillMount() {
    this.project = this.props.project;
  }
  render() {
    this.project = this.props.project;
    if (this.props.project) {
      return (
        <div style={{backgroundImage: 
          `url(${host +
            "uploads/" +
            this.project.cover})`,
            height: "650px",backgroundSize: "cover",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>

</div>
      );
    } else {
      return <div></div>;
    }
  }
}
export default acceuil;
