import React, { Component } from "react";
import image1 from "../../Images/image1.PNG";
import logo1 from "../../Images/darlogo.png";
import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";
import "./../../../node_modules/react-slick/dist/react-slick";
import host from '../../config/api';
import mainapp from '../../config/env';
import ProjectService from "../../services/project-service";
import { faRedditSquare } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';

// import Carousel from 'bee-carousel';

export class noprojet extends Component {

  constructor(props) {
    super(props);
    this.projectService = new ProjectService();
    this.state = { projects: [], status: false,indexnprojet:0,indexvprojet:0 };
    console.log('index',props);
  }
  async componentWillMount() {
    await this.projectService.getAllProjects().then(data => {
      
      this.setState({ projects: data["data"].projects, status: true });
      //localStorage.setIte
    });
  }
  getRoute(route) {


    return "/" + route;
  }
  render() {
    // this.project = this.props.project;
    const params = {
      pagination: {
        el: ".swiper-pagination1",
        clickable: true
      },
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    };
    if (this.state.projects.length > 0) {
      return (
        <div style={{ height: "0" }}>
          {/* ---- modal -- */}
          <div
            class="modal fade swiper-slider "
            id="noprojet"
            tabindex="-1"
            role="dialog"
            aria-labelledby="noprojet"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="ModalLabel">
                    NOS PROJETS
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-3">
                      <h4> Villas</h4>
                    </div>
                    <div class="col-md-6">
                      <h4> Haut Standing</h4>
                    </div>
                    <div class="col-md-3">
                      <h4> Moyen Standing</h4>
                    </div>
                  </div>

                  <div class="row slide-home">
                    <div class="col-md-3">
                    <div
                        id="carouselVprojets"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div class="carousel-inner">

                      {this.state.projects.map((project, index) => {
                        if (project.type === 'Villas') {
                          if(this.state.indexvprojet === 0){
                            this.setState({ indexvprojet : index });
                          }
                          const cls =
                                index === this.state.indexvprojet
                                  ? "carousel-item active"
                                  : "carousel-item";
                          return (
                            <div class={cls}>
                            <div class="swiper-slide">
                              <div
                                style={{
                                  backgroundImage: `url(${host +
                                    "uploads/" + project.cover})`,
                                  backgroundSize: "cover",
                                  height: "400px",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat"
                                }}
                              >
                                <div class="col-5 txthover">
                                  {" "}
                                  <img src={host + 'uploads/' + project.logoForHome} class="d-block w-100" alt={project.logoForHome} />
                                  <p>
                                    {project.descriptionMenu}
                                  </p>
                                  <Link class="btn" to={this.getRoute(project.route)}>
                                    Découvrir
                                  </Link>
                                </div>
                              </div>
                            </div>
                            </div>
                          );
                        }
                      })
                      }

                    </div>
                        <a
                          class="carousel-control-prev"
                          href="#carouselVprojets"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          class="carousel-control-next"
                          href="#carouselVprojets"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="row">

                        {this.state.projects.map(project => {
                          if (project.type === 'Haut Standing') {
                            return (
                              <div class="col-md-4">
                                <div class="swiper-slide">
                                  <div
                                    style={{
                                      backgroundImage: `url(${host +
                                        "uploads/" + project.cover})`,
                                      backgroundSize: "cover",
                                      height: "400px",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat"
                                    }}
                                  >
                                    <div class="col-5 txthover">
                                      {" "}
                                      <img src={host + 'uploads/' + project.logoForHome} class="d-block w-100" alt={project.logoForHome} />
                                      <p>
                                        {project.descriptionMenu}
                                      </p>
                                      <Link class="btn" to={this.getRoute(project.route)}>
                                        Découvrir
                                  </Link>
                                    </div>
                                  </div>
                                </div></div>
                            );
                          }
                        })

                        }
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div
                        id="carouselNprojets"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div class="carousel-inner">



                          {this.state.projects.map((project, index) => {
                            if (project.type === 'Moyen Standing') {
                              if(this.state.indexnprojet === 0){
                                this.setState({ indexnprojet : index });
                              }
                              
                              const cls =
                                index === this.state.indexnprojet
                                  ? "carousel-item active"
                                  : "carousel-item";
                              return (
                                <div class={cls}>
                                  <div class="swiper-slide">
                                    <div
                                      style={{
                                        backgroundImage: `url(${host +
                                          "uploads/" + project.cover})`,
                                        backgroundSize: "cover",
                                        height: "400px",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat"
                                      }}
                                    >
                                      <div class="col-5 txthover">
                                        {" "}
                                        <img src={host + 'uploads/' + project.logoForHome} class="d-block w-100" alt={project.logoForHome} />
                                        <p>
                                          {project.descriptionMenu}
                                        </p>
                                        <Link class="btn" to={this.getRoute(project.route)}>
                                          Découvrir
                                  </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>);
                            }
                          })
                          }
                        </div>
                        <a
                          class="carousel-control-prev"
                          href="#carouselNprojets"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a
                          class="carousel-control-next"
                          href="#carouselNprojets"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- fin modal  -- */}
          <br />
        </div>
      );
    } else { return (<div></div>) }

  }
}

export default noprojet;
