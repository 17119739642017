import React, { Component } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export class Footer extends Component {
  render() {
    return (
      <div>
        <footer class="footer">
          <div class="col-md-3">
            <ul class="url">
              <li>
                <a class="url" target="_blank" href="/conditions-generales">
                  CONDITIONS GÉNÉRALES D’UTILISATION
                </a>
              </li>
              <li>
                <a
                  class="url"
                  target="_blank"
                  href="/politique-de-confidentialite"
                >
                  POLITIQUE DE CONFIDENTIALITÉ
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-copyright text-center">
            © 2020 CHAABANE IMMOBILIER
            <div
              style={{ float: "right", right: "40px", position: "relative" }}
            >
              <a
                class="social-media smedia-icon"
                target="_blank"
                href="https://fr-fr.facebook.com/Chaabaneimmobilier/"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                class="social-media smedia-icon"
                target="_blank"
                href="https://www.linkedin.com/company/chaabane-immobilier/"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                class="social-media smedia-icon"
                target="_blank"
                href="https://www.instagram.com/chaabane.immobilier/?hl=fr"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
