import React, { Component } from "react";
import "./video.css";
import Carousel from "bee-carousel";

export class Video extends Component {
  constructor(props) {
    super(props);
    this.index = 0;

    this.project = {};
  }
  componentWillMount() {
    this.project = this.props.project;
    console.log('projet',this.project);
  }
  render() {
    if (this.props.project) {
      const paramsslidehome = {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      };
      const projects =this.props.project.urlYoutube;

      return (
        <div class="section section-padding">
          {" "}
          <div class="row">
            <div class="zone-title">
              <h2 class="title">Video</h2>
            </div>
          </div>
          <div class="container">
            <div class="row video">
              <div class="col-md-8">
              <div id="carouselExampleControlsvideo" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
                    {projects.map((url, index) => (
                      <div class={index === this.index ? 'carousel-item active': 'carousel-item'}>
             <iframe
             width="560"
             height="315"
             src={url}
             frameborder="0"
             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
             allowfullscreen
           ></iframe>
    </div>
                                              ))}
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControlsvideo" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControlsvideo" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>                
             
              </div>
              <div class="col-md-4">
                <div class="all-centre">
                  <h2 style={{ color: "#fff" }}>
                    <span>videothéque</span>
                    <br /> du projet
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Video;
