import React, { Component } from "react";
import Carousel from "bee-carousel";
import image1 from "../../Images/image1.PNG";
import image2 from "../../Images/image2.PNG";
import image3 from "../../Images/image3.PNG";
import image4 from "../../Images/image4.png";
import $ from "jquery";
import MediaQuery from "react-responsive";
import Noprojet from "../projet/nosprojet"
import HeaderHome from "../websitelayout/Header-home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";

import ActualityService from '../../services/actuality-service';
import host from "../../config/api";
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = { actuality: {}, date:'' };
    this.actualityService = new ActualityService();

  }

  async componentWillMount() {
    if (this.props.match.params) {
      await this.actualityService.getActualityInfos(this.props.match.params.ref).then(data => {
        this.setState({ actuality: data["data"].Actuality, status: true });
      });
      const months = ["JANVIER", "FÉVRIER", "MARS","AVRIL", "MAI", "JUIN", "JUILLET", "AOÛT", "SEPTEMBRE", "OCTOBRE", "NOVEMBRE", "DÉCEMBRE"];
        let current_datetime = new Date(this.state.actuality.datePobulication);
        let formatted_date = current_datetime.getDate() + "-" + months[current_datetime.getMonth()] + "-" + current_datetime.getFullYear();
        this.setState({date:formatted_date});
    }
  }
  componentDidMount() {
    $("body").removeClass();
    $("body").addClass("actuality");
    $('#inputProjectFooter option[value=""]').prop('selected', true);
  }
  render() {

    if (this.state.actuality) {
      return (
        <div>
          <MediaQuery maxDeviceWidth={790}>
            {/* mobile */}
            <HeaderHomeMobile />
            <div
              style={{ backgroundColor: "white", padding: "80px 10px" }}
            >

              <h1
                class="actuality-titre"  >
                NOS ACTUALITÉS
        </h1>
              <div class="container">
                <div class="row">
                  {/*<div class="article col-md-10">
                    <img src={image2} class="d-block w-100" alt="..." />
                    <div>
                      <p class="title">Lorem ipsum test text </p>
                      <p class="date" ><FontAwesomeIcon icon={faCalendarAlt} /> 06 / Aout / 2018 </p>
                      <p class="content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea commodo consequat.
                      </p>
                    </div>
      </div>{*/}
                   <div class="article col-md-10">
                    <img src={host + 'uploads/' + this.state.actuality.image} class="d-block w-100" alt={this.state.actuality.image} />
                    <div>
                      <p class="title">{this.state.actuality.title} </p>
                      <p class="date" ><FontAwesomeIcon icon={faCalendarAlt} /> {this.state.date} </p>
                      <div dangerouslySetInnerHTML={{ __html: this.state.actuality.text }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
         {/*} <MediaQuery minDeviceWidth={790}>
             
      </MediaQuery>{*/}
          <MediaQuery minDeviceWidth={790}>
            {/* desktop */}
            <Noprojet />
            <HeaderHome />

            <div
              class="home-page"
              style={{ backgroundColor: "white", padding: "80px 10px" }}
            >

              <h1
                style={{
                  textAlign: "center",
                  color: "#0d0c0b ",
                  margin: "0 auto 50px auto",
                  borderColor: "#b7853e"
                }}
              >
                NOS ACTUALITÉS
          </h1>

              <div class="container">
                <div class="row">
                  <div class="article col-md-10">
                    <img src={host + 'uploads/' + this.state.actuality.image} class="d-block w-100" alt={this.state.actuality.image} />
                    <div>
                      <p class="title">{this.state.actuality.title} </p>
                      <p class="date" ><FontAwesomeIcon icon={faCalendarAlt} /> {this.state.date} </p>
                      <div dangerouslySetInnerHTML={{ __html: this.state.actuality.text }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
        </div>
      );
    } else {
      return (<div><MediaQuery maxDeviceWidth={790}>
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>

        </MediaQuery></div>
        )
    }

  }
}

export default index;
