import React, { Component } from "react";
import pos1 from "../../Images/pos-1.png";
import pos2 from "../../Images/pos-2.png";
import pos3 from "../../Images/pos-3.png";
import pos4 from "../../Images/pos-4.png";
import pos5 from "../../Images/pos-5.png";
import pos6 from "../../Images/pos-6.png";

export class localisation extends Component {
  constructor(props) {
    super(props);
    this.project = {};
  }
  componentWillMount() {
    this.project = this.props.project;
  }
  render() {
    if (this.props.project) {
      return (
        <>
          { this.props.project.reference !== "CH28474" &&
            <div
          class="text-center"
          style={{
            backgroundColor: "white",
            padding: "0px 8px",
            width: "max-content",
            margin: "0 auto",
            lineHeight: "1"
          }}
        >
            <iframe
            title="maps"
            src={this.props.project.urlMaps}
            width="600"
            height="450"
            frameborder="0"
            style={{ border: "5px solid white", margin: "10px auto" }}
            allowfullscreen=""
          ></iframe>
          </div>
          }
          
          { this.props.project.reference === "CH28474" &&
          <div class="container-fluid">
          <div class="row">
            <div class="col-md-7">
              <iframe
              title="maps"
              src={this.props.project.urlMaps}
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: "5px solid white", margin: "10px auto" }}
              allowfullscreen=""
            ></iframe>
            </div>
            <div class="col-md-5">
            <div class="container">
  <h1 class="mb-4">AN EXCEPTIONAL LOCATION</h1>
  
  <p class="mb-4">
    Anfa Bloom bénéficie d'une localisation d'exception, alliant centralité et sécurité, au cœur du prestigieux quartier Casa Anfa. Ce projet unique promet un style de vie dynamique et moderne, offrant à ses résidents un cadre de vie exceptionnel où confort et commodités se rencontrent.
  </p>
  
  <div class="row row-cols-2 row-cols-md-3 g-4">
    <div class="col">
      <div class="d-flex flex-column align-items-center">
        <img src={pos1} class="d-block" alt="..." />
        <p class="text-center mb-0"><strong>2 min</strong></p>
        <p class="text-center">de la nouvelle ambassade des États-Unis</p>
      </div>
    </div>
    <div class="col">
      <div class="d-flex flex-column align-items-center">
      <img src={pos2} class="d-block" alt="..." />
      <p class="text-center mb-0"><strong>5 min</strong></p>
        <p class="text-center">du Parc Casa Anfa</p>
      </div>
    </div>
    <div class="col">
      <div class="d-flex flex-column align-items-center">
      <img src={pos3} class="d-block" alt="..." />
      <p class="text-center mb-0"><strong>5 min</strong></p>
        <p class="text-center">de Anfa Mall</p>
      </div>
    </div>
    <div class="col">
      <div class="d-flex flex-column align-items-center">
      <img src={pos4} class="d-block" alt="..." />
      <p class="text-center mb-0"><strong>5 min</strong></p>
        <p class="text-center">de la Tour CFC</p>
      </div>
    </div>
    <div class="col">
      <div class="d-flex flex-column align-items-center">
      <img src={pos5} class="d-block" alt="..." />
      <p class="text-center mb-0"><strong>15 min</strong></p>
        <p class="text-center">de la Plage d'Ain Diab</p>
      </div>
    </div>
    <div class="col">
      <div class="d-flex flex-column align-items-center">
      <img src={pos6} class="d-block" alt="..." />
      <p class="text-center mb-0"><strong>20 min</strong></p>
        <p class="text-center">de l'Aéroport</p>
      </div>
    </div>
  </div>
</div>
            </div>
          </div>
          </div>
          }
        </>
      );
    } else {
      return <div></div>;
    }
  }
}

export default localisation;
