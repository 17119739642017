import React, { Component } from "react";
import image from "../../Images/description.jpg";
import host from "../../config/api";
import MediaQuery from "react-responsive";

export class decription extends Component {
  constructor(props) {
    super(props);

    this.project = {};
  }
  componentWillMount() {
    this.project = this.props.project;
  }
  render() {
    if (this.props.project) {
      const description = this.props.project.description;

      return (
        <div>
          <MediaQuery maxDeviceWidth={790}>
            {/* mobile */}
            <div class="mobile-desc">
              <div class="row align-items-center" style={{ margin: "30px" }}>
                <div class="col-md-6">
                  <h1 style={{ width: "fit-content" }}>{description.title}</h1>
                  <h4
                    style={{
                      fontFamily: "montserrat",
                      fontStyle: "italic",
                      fontSize: "20px"
                    }}
                  >
                    {description.subTitle}
                  </h4>

                  <div style={{ textAlign: "justify",textJustify: "inter-word" }} dangerouslySetInnerHTML={{ __html: description.text }} />
                  <div id="notregroupe-image" class="col-md-6">
                    <img src={host + "uploads/" + description.image} alt=""></img>
                  </div>
                  <a class="btn" target="_blank" href={host + "uploads/" + description.brochure} style={{ margin: "52px auto 0", display: "block", width: "fit-content", justifyConent: "center" }} download>
                    <i class="fa fa-download"></i>Télécharger la brochure
              </a>
                </div>

              </div>          </div>
          </MediaQuery>
          <MediaQuery minDeviceWidth={790}>
            {/* desktop */}
            <div class="row align-items-center" style={{ margin: "30px" }}>
              <div class="col-md-6">
                <h1 style={{ width: "fit-content" }}>{description.title}</h1>
                <h4
                  style={{
                    fontFamily: "montserrat",
                    fontStyle: "italic",
                    fontSize: "20px"
                  }}
                >
                  {description.subTitle}
                </h4>
                <div>
                  <p style={{ textAlign: "justify",textJustify: "inter-word" }} dangerouslySetInnerHTML={{ __html: description.text }} />
                </div>
                <a class="btn" target="_blank" href={host + "uploads/" + description.brochure} style={{ margin: "0 auto", display: "block", width: "fit-content", justifyConent: "center" }} download>
                  <i class="fa fa-download"></i>Télécharger la brochure
              </a>
              </div>
              <div id="notregroupe-image" class="col-md-6">
                <img src={host + "uploads/" + description.image} alt=""></img>
              </div>
            </div>
          </MediaQuery>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default decription;
