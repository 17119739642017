import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import host from "./../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faPhoneAlt,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import logo from "../../Images/darlogo2.png";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import Contactpopup from "./Contact-popup";
import $ from "jquery";

export class Header extends Component {




  constructor(props) {
    super(props);
    this.project = {};
  }
  componentWillMount() {
    this.project = this.props.project;
  }

  render() {
    if (this.props.project) {
      return (
        <div>
          <nav id="Header" class="navbar navbar-expand-lg">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item info">
                <FontAwesomeIcon icon={faPhoneAlt} />
                <a href="tel:+212522211717">+212 522 21 17 17</a> / <a href="tel:+212614989898">+212
                614 98 98 98</a>
              </li>
              <li class="nav-item info">
                <FontAwesomeIcon icon={faEnvelope} />{" "}
                <a href="mailto:contact@chaabaneinvest.com">contact@chaabaneinvest.com</a>
              </li>
            </ul>
            <ul class="navbar-nav ml-auto">
              <a class="social-media" target="_blank" href="https://fr-fr.facebook.com/Chaabaneimmobilier/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a class="social-media" target="_blank" href="https://www.linkedin.com/company/chaabane-immobilier">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a class="social-media" target="_blank" href="https://www.instagram.com/chaabane.immobilier/?hl=fr">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a class="social-media ml-1" target="_blank" href="https://www.youtube.com/channel/UCUFlcKD70nQZj4s4Ky0yHHQ">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </ul>
          </nav>
          <nav id="Header2" class="navbar navbar-expand-lg">
            <img
              src={host + "uploads/" + this.props.project.logoP}
              class="d-block"
              alt="..."
            />
            <ul class="navbar-nav ml-auto">
              <li class="item">
                <Link class="link" to="/">
                ACCUEIL
                </Link>
              </li>
              <li class="item">
                <Link class="link" to="/Apropos">
                  A PROPOS
                </Link>
              </li>
              <li class="item">
                            <Link class="link" data-toggle="modal" data-target="#noprojet">NOS PROJETS</Link>
                        </li>
              <li class="item">
                <span class="contact-popup-btn">NOUS CONTACTER</span>
              </li>
            </ul>
          </nav>
          {this.props.project.logoP && this.props.project.title && this.props.projects ? (
            <div class="contact-popup hidden">
              <Contactpopup project={this.props.project} projects={this.props.projects} />
            </div>
          ) : (
              <div></div>
            )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Header;
