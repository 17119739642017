import React, { Component } from "react";
import "./index.css";
import Noprojet from "../projet/nosprojet";
import HeaderHome from "../websitelayout/Header-home";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import $ from "jquery";
import MediaQuery from "react-responsive";

export class index extends Component {
  componentDidMount() {
    $("body").removeClass();
    $("body").addClass("home-page conditions");
    $('#inputProjectFooter option[value=""]').prop("selected", true);
  }
  render() {
    return (
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
          <div id="vision-text" class="col-md-12 ml-2">
            <h1>CONDITIONS GÉNÉRALES</h1>
            <div class="ml-1">
              <h4>CONDITIONS D’UTILISATION DU SITE</h4>
              <p>
                L’utilisation de ce site et ses diverses fonctions est soumise à
                la réglementation en vigueur et aux présentes conditions
                d’utilisation. Vous êtes informés que tout ce que vous
                visualisez ou lisez et/ou utilisez sur ce site internet est la
                propriété exclusive du Groupe est protégé par le droit de la
                propriété intellectuelle.
              </p>
              <b>1. Formulaire de contact</b>
              <p>
                Les informations personnelles communiquées au Groupe Chaabane
                Immobilier via le formulaire de contact dédié sont utilisées
                uniquement pour répondre aux requêtes de l'utilisateur. Elles ne
                sont accessibles qu’aux services compétents du Groupe Chaabane
                Immobilier, ne sont communiquées à aucun tiers. CVs Les
                Curriculum vitae communiqués au Groupe Chaabane Immobilier sont
                exploités exclusivement par le service des Ressources Humaines à
                des fins de recrutement. Ils sont stockés dans une base de
                données interne.
              </p>
              <b>2. Propriété</b>
              <p>
                L’utilisation et la consultation du site est permise au public
                uniquement pour des fins d’information et de promotion des
                produits et services du Groupe Aucune exploitation quelque que
                soit la nature des données qui y sont présentées ne pourra être
                effectuée sans l’accord préalable et écrit du Groupe. La
                présentation et le contenu du présent site, constituent
                ensemble, une œuvre protégée par les lois en vigueur sur la
                propriété Aucune reproduction et/ou représentation ou même
                référence partielle ou intégrale à ce site, ne pourra en être
                faite sans l’accord préalable et écrit du Groupe. Toute
                utilisation et/ou modification qui en serait faite sans l’accord
                préalable et écrit du Groupe est susceptible d’en constituer une
                infraction pénale susceptible de poursuites judiciaires.
              </p>
              <b>3. Limitation de la responsabilité</b>
              <p>
                La responsabilité du Groupe, leurs dirigeants, administrateurs,
                agents, employés, représentants ne pourra en aucun cas être
                retenue pour quelque dommage que ce soit : direct, indirect,
                spécial, accessoire, punitif ou exemplaire (y compris, mais sans
                s’y limiter, la perte de revenus ou de bénéfices, la
                non-réalisation d’économies anticipées, l’interruption
                d’activités ou toute autre perte financière ou économique)
                découlant de l’utilisation ou de l’impossibilité d’utiliser ce
                Site.
              </p>
              <b>4. Exonérations</b>
              <p>
                Toutes les informations contenues sur ce Site sont fournies
                uniquement à titre de renseignement et de promotion et ne
                constituent en aucun cas une proposition ni une offre ferme de
                vente. Les informations fournies sur ce site, y compris, mais
                sans s’y limiter, les projets, les prix, les propositions de
                financement peuvent subir des modifications, nos mises à jour
                sur le site, et de ce fait ne peuvent se substituer ou modifier
                les informations fournies par la Direction commerciale du
                Groupe.
              </p>
              <b>5. Protection des données</b>
              <br></br>
              <b>
                5.1 Conformité de la collecte et du traitement des données
                personnelles
              </b>
              <p>
                Conformément aux dispositions de la loi n°09-08 relative à la
                protection des personnes physiques à l’égard du traitement des
                données à caractère personnel, le Client reconnaît et accepte
                que les données à caractère personnel(nom, prénom, adresse,
                etc.) déclarées par lui ou recueillies lors de l’utilisation de
                site web (www.chaabaneimmobilier.com) sont destinées à informer
                les prospects et clients sur les nouveaux lancements, les
                informations corporate ainsi que de l’avancement de nos
                programmes et font l’objet d’opérations de traitement
                proportionnel avec une finalité limitée dans l’objet et dans le
                temps pour les besoins uniques des finalités techniques,
                marketing, commerciales, d’information sur les projets, produits
                et services proposés sur le site (www.chaabaneimmobilier.com) Le
                visiteur et l’utilisateur du site (www.chaabaneimmobilier.com)
                reconnaît expressément et sans réserve, avoir été amplement
                informé et sensibilisé préalablement sur les finalités de la
                collecte des données , la nature et l’objet du traitement de ses
                données collectées. Le visiteur et l’utilisateur est informé par
                les présentes de tous les droits que lui confère la loi n°09-08
                et de toutes les informations devant être communiquées
                préalablement, conformément à la loi susvisée. Le visiteur et
                l’utilisateur déclare avoir pris connaissance des présentes et
                avoir compris la teneur et donne expressément et sans réserve
                son consentement libre, spécifique et informé aux fins desdits
                traitements, notamment pour qu’il soit prospecté directement par
                automate d’appel, télécopieur, courrier électronique ou tout
                autre moyen employant une technologie de même nature.
              </p>
              <b>5.2 Déclaration et autorisation CNDP</b>
              <p>
                Les données à caractère personnel recueillies dans le cadre de
                ce site ont fait l’objet d’une déclaration à la Commission
                Nationale de Contrôle des Données à caractère Personnel (la
                CNDP). Par le biais de l’ensemble des formulaires présent sur ce
                site, notre service client collecte vos données personnelles en
                vue de vous contacter pour nos offres futures. Ce traitement a
                fait l’objet d’une demande d’autorisation auprès de la CNDP.
                Vous pouvez vous adresser à contact@chaabaneinvest.com pour
                exercer vos droits d’accès, de rectification et d’opposition
                conformément aux dispositions de la loi 09-08. Les utilisateurs
                peuvent obtenir à cet effet les renseignements nécessaires
                auprès de cet organisme.
              </p>
              <b>6. Droits d’auteurs et autres droits</b>
              <p>
                Le contenu de ce site Internet est la propriété exclusive du
                Groupe il ne doit être ni copié, ni diffusé, modifié ou rendu
                accessible à des tiers sans l’accord écrit préalable du Groupe
                Chaabane Immobilier. Les textes, graphiques, bandes sonores,
                animations, screensavers, séquences vidéos, etc. font en
                principe l’objet d’une protection du droit d’auteur ou d’autres
                droits de propriété intellectuelle. Les marques commerciales
                sont protégées selon le droit des marques.
              </p>
              <b>7. Liens</b>
              <p>
                Les liens hypertextes mis en place dans le cadre du site
                internet en direction d’autres ressources présentes sur le site
                internet ne sauraient engager la responsabilité du Groupe,
                notamment sa responsabilité, au regard de l’ensemble du contenu
                des autres ressources directes ou indirectes. Le Groupe décline
                toute responsabilité s’agissant des liens hypertextes qui
                peuvent apparaître sur le site et interdit à toute personne de
                mettre en place un tel lien sans son autorisation préalable.
              </p>
              <b>8. Cookies</b>
              <p>
                Lorsque vous utilisez notre site Internet, des « cookies » sont
                stockés sur votre ordinateur. De tels cookies enregistrent des
                informations sur la navigation de votre ordinateur sur notre
                site Internet (pages choisies, jour, heure et durée de
                l’utilisation, etc.). Nous pouvons ensuite lire ces informations
                puis adapter notre site Internet à vos besoins personnels et
                optimiser les durées de chargement. Cela nous permettra de mieux
                connaître vos centres d’intérêts. Vous avez la possibilité de
                contrôler vous-mêmes l’acceptation de ces cookies et le cas
                échéant de les refuser. Pour cela, il vous suffit de configurer
                votre navigateur.
              </p>
              <b>9. Durée</b>
              <p>
                Les présentes conditions s’appliquent pendant toute la durée de
                mise en ligne des services offerts par le Groupe Chaabane
                Immobilier sur le site (www.chaabaneimmobilier.com)
              </p>
              <b>10. Loi applicable et compétence</b>
              <p>
                Les présentes conditions sont soumises à la loi marocaine.
                L’attribution de compétence en cas de litige, et à défaut
                d’accord amiable entre les parties, est donnée au Tribunal de
                Commerce de Casablanca.
              </p>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Noprojet />
          <HeaderHome />
          <div id="vision-text" class="col-md-12 ml-1">
            <h1>CONDITIONS GÉNÉRALES</h1>
            <div class="ml-2">
              <h4>CONDITIONS D’UTILISATION DU SITE</h4>
              <p>
                L’utilisation de ce site et ses diverses fonctions est soumise à
                la réglementation en vigueur et aux présentes conditions
                d’utilisation. Vous êtes informés que tout ce que vous
                visualisez ou lisez et/ou utilisez sur ce site internet est la
                propriété exclusive du Groupe est protégé par le droit de la
                propriété intellectuelle.
              </p>
              <b>1. Formulaire de contact</b>
              <p>
                Les informations personnelles communiquées au Groupe Chaabane
                Immobilier via le formulaire de contact dédié sont utilisées
                uniquement pour répondre aux requêtes de l'utilisateur. Elles ne
                sont accessibles qu’aux services compétents du Groupe Chaabane
                Immobilier, ne sont communiquées à aucun tiers. CVs Les
                Curriculum vitae communiqués au Groupe Chaabane Immobilier sont
                exploités exclusivement par le service des Ressources Humaines à
                des fins de recrutement. Ils sont stockés dans une base de
                données interne.
              </p>
              <b>2. Propriété</b>
              <p>
                L’utilisation et la consultation du site est permise au public
                uniquement pour des fins d’information et de promotion des
                produits et services du Groupe Aucune exploitation quelque que
                soit la nature des données qui y sont présentées ne pourra être
                effectuée sans l’accord préalable et écrit du Groupe. La
                présentation et le contenu du présent site, constituent
                ensemble, une œuvre protégée par les lois en vigueur sur la
                propriété Aucune reproduction et/ou représentation ou même
                référence partielle ou intégrale à ce site, ne pourra en être
                faite sans l’accord préalable et écrit du Groupe. Toute
                utilisation et/ou modification qui en serait faite sans l’accord
                préalable et écrit du Groupe est susceptible d’en constituer une
                infraction pénale susceptible de poursuites judiciaires.
              </p>
              <b>3. Limitation de la responsabilité</b>
              <p>
                La responsabilité du Groupe, leurs dirigeants, administrateurs,
                agents, employés, représentants ne pourra en aucun cas être
                retenue pour quelque dommage que ce soit : direct, indirect,
                spécial, accessoire, punitif ou exemplaire (y compris, mais sans
                s’y limiter, la perte de revenus ou de bénéfices, la
                non-réalisation d’économies anticipées, l’interruption
                d’activités ou toute autre perte financière ou économique)
                découlant de l’utilisation ou de l’impossibilité d’utiliser ce
                Site.
              </p>
              <b>4. Exonérations</b>
              <p>
                Toutes les informations contenues sur ce Site sont fournies
                uniquement à titre de renseignement et de promotion et ne
                constituent en aucun cas une proposition ni une offre ferme de
                vente. Les informations fournies sur ce site, y compris, mais
                sans s’y limiter, les projets, les prix, les propositions de
                financement peuvent subir des modifications, nos mises à jour
                sur le site, et de ce fait ne peuvent se substituer ou modifier
                les informations fournies par la Direction commerciale du
                Groupe.
              </p>
              <b>5. Protection des données</b>
              <br></br>
              <b>
                5.1 Conformité de la collecte et du traitement des données
                personnelles
              </b>
              <p>
                Conformément aux dispositions de la loi n°09-08 relative à la
                protection des personnes physiques à l’égard du traitement des
                données à caractère personnel, le Client reconnaît et accepte
                que les données à caractère personnel(nom, prénom, adresse,
                etc.) déclarées par lui ou recueillies lors de l’utilisation de
                site web (www.chaabaneimmobilier.com) sont destinées à informer
                les prospects et clients sur les nouveaux lancements, les
                informations corporate ainsi que de l’avancement de nos
                programmes et font l’objet d’opérations de traitement
                proportionnel avec une finalité limitée dans l’objet et dans le
                temps pour les besoins uniques des finalités techniques,
                marketing, commerciales, d’information sur les projets, produits
                et services proposés sur le site (www.chaabaneimmobilier.com) Le
                visiteur et l’utilisateur du site (www.chaabaneimmobilier.com)
                reconnaît expressément et sans réserve, avoir été amplement
                informé et sensibilisé préalablement sur les finalités de la
                collecte des données , la nature et l’objet du traitement de ses
                données collectées. Le visiteur et l’utilisateur est informé par
                les présentes de tous les droits que lui confère la loi n°09-08
                et de toutes les informations devant être communiquées
                préalablement, conformément à la loi susvisée. Le visiteur et
                l’utilisateur déclare avoir pris connaissance des présentes et
                avoir compris la teneur et donne expressément et sans réserve
                son consentement libre, spécifique et informé aux fins desdits
                traitements, notamment pour qu’il soit prospecté directement par
                automate d’appel, télécopieur, courrier électronique ou tout
                autre moyen employant une technologie de même nature.
              </p>
              <b>5.2 Déclaration et autorisation CNDP</b>
              <p>
                Les données à caractère personnel recueillies dans le cadre de
                ce site ont fait l’objet d’une déclaration à la Commission
                Nationale de Contrôle des Données à caractère Personnel (la
                CNDP). Par le biais de l’ensemble des formulaires présent sur ce
                site, notre service client collecte vos données personnelles en
                vue de vous contacter pour nos offres futures. Ce traitement a
                fait l’objet d’une demande d’autorisation auprès de la CNDP.
                Vous pouvez vous adresser à contact@chaabaneinvest.com pour
                exercer vos droits d’accès, de rectification et d’opposition
                conformément aux dispositions de la loi 09-08. Les utilisateurs
                peuvent obtenir à cet effet les renseignements nécessaires
                auprès de cet organisme.
              </p>
              <b>6. Droits d’auteurs et autres droits</b>
              <p>
                Le contenu de ce site Internet est la propriété exclusive du
                Groupe il ne doit être ni copié, ni diffusé, modifié ou rendu
                accessible à des tiers sans l’accord écrit préalable du Groupe
                Chaabane Immobilier. Les textes, graphiques, bandes sonores,
                animations, screensavers, séquences vidéos, etc. font en
                principe l’objet d’une protection du droit d’auteur ou d’autres
                droits de propriété intellectuelle. Les marques commerciales
                sont protégées selon le droit des marques.
              </p>
              <b>7. Liens</b>
              <p>
                Les liens hypertextes mis en place dans le cadre du site
                internet en direction d’autres ressources présentes sur le site
                internet ne sauraient engager la responsabilité du Groupe,
                notamment sa responsabilité, au regard de l’ensemble du contenu
                des autres ressources directes ou indirectes. Le Groupe décline
                toute responsabilité s’agissant des liens hypertextes qui
                peuvent apparaître sur le site et interdit à toute personne de
                mettre en place un tel lien sans son autorisation préalable.
              </p>
              <b>8. Cookies</b>
              <p>
                Lorsque vous utilisez notre site Internet, des « cookies » sont
                stockés sur votre ordinateur. De tels cookies enregistrent des
                informations sur la navigation de votre ordinateur sur notre
                site Internet (pages choisies, jour, heure et durée de
                l’utilisation, etc.). Nous pouvons ensuite lire ces informations
                puis adapter notre site Internet à vos besoins personnels et
                optimiser les durées de chargement. Cela nous permettra de mieux
                connaître vos centres d’intérêts. Vous avez la possibilité de
                contrôler vous-mêmes l’acceptation de ces cookies et le cas
                échéant de les refuser. Pour cela, il vous suffit de configurer
                votre navigateur.
              </p>
              <b>9. Durée</b>
              <p>
                Les présentes conditions s’appliquent pendant toute la durée de
                mise en ligne des services offerts par le Groupe Chaabane
                Immobilier sur le site (www.chaabaneimmobilier.com)
              </p>
              <b>10. Loi applicable et compétence</b>
              <p>
                Les présentes conditions sont soumises à la loi marocaine.
                L’attribution de compétence en cas de litige, et à défaut
                d’accord amiable entre les parties, est donnée au Tribunal de
                Commerce de Casablanca.
              </p>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default index;
