import React, { Component } from "react";
import image from "../../Images/silde2.jpg";
import {
  faFacebook,
  faYoutube,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faMapMarkerAlt,
  faFax,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../Images/logochaabane.png";
import host from "../../config/api";
import ProjectService from "../../services/project-service";
import Projects from "../websitelayout/projects";
import Countries from "../websitelayout/frCountries";
import $ from "jquery";
import { withRouter } from "react-router-dom";

export class ContactMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
      firstName: "",
      lastName: "",
      email: "",
      project: "",
      message: "",
      country: "Maroc",
      phonePrefix: "+212",
      phoneNumber: "",
      projectRef: "",
      refer: "",
      condition: false,
      messageError: "",
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.handleRefer = this.handleRefer.bind(this);
    this.handleProject = this.handleProject.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handlePrefix = this.handlePrefix.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleCondition = this.handleCondition.bind(this);
  }
  componentDidMount() {
    $(".infocontactlp .gotomap").click(function () {
      $(".infocontactlp").slideUp();
      $(".mapcontact").slideDown();
    });
    $(".backinfos").click(function () {
      $(".infocontactlp").slideDown();
      $(".mapcontact").slideUp();
    });

    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }
  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }
  handleRefer(event) {
    this.setState({ refer: event.target.value });
  }
  handleProject(event) {
    this.setState({ projectRef: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phoneNumber: event.target.value });
  }
  handlePrefix(event) {
    this.setState({ phonePrefix: event.target.value });
  }
  handleCountry(event) {
    this.setState({ country: event.target.value });
    Countries.map((option) => {
      if (option.name === event.target.value) {
        this.setState({ phonePrefix: option.dial_code });
      }
    });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleLastName(event) {
    this.setState({ lastName: event.target.value });
  }
  handleFirstName(event) {
    this.setState({ firstName: event.target.value });
  }
  handleCondition(event) {
    this.setState({ condition: event.target.checked });
  }

  renderOption() {
    return Countries.map((option) =>
      option.name === "Maroc" ? (
        <option key={option.dial_code} selected value={option.name}>
          {option.name}
        </option>
      ) : (
        <option key={option.dial_code} value={option.name}>
          {option.name}
        </option>
      )
    );
  }
  renderOptionProjects() {
    if (Projects)
      return Projects.map((option) => (
        <option value={option.ref}>{option.title}</option>
      ));
  }

  async send(event) {
    event.preventDefault();

    /* if (!this.state.firstName || this.state.firstName === "") {
       return false;
     }
     if (!this.state.lastName || this.state.lastName === "") {
       return false;
     }
     if (!this.state.email || this.state.email === "") {
       return false;
     }
     if (!this.state.projectRef || this.state.projectRef === "") {
       return false;
     } else {
       await Projects.map(project => {
         if (project.ref === this.state.projectRef) {
           this.setState({ project: project.title });
         }
       });
     }
 
     if (!this.state.country || this.state.country === "") {
       return false;
     }
     if (
       !this.state.phonePrefix ||
       this.state.phonePrefix === ""
     ) {
       return false;
     }
     if (
       !this.state.phoneNumber ||
       this.state.phoneNumber === ""
     ) {
       return false;
     }
     if (!this.state.refer || this.state.refer === "") {
       return false;
     }
     if (
       !this.state.condition ||
       this.state.condition === false
     ) {
       return false;
     }*/

    if (!this.state.lastName || this.state.lastName === "") {
      this.setState({ messageError: "Veuillez saisir votre nom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      this.setState({ messageError: "Veuillez saisir votre prénom" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.country || this.state.country === "") {
      this.setState({ messageError: "Veuillez choisir votre pays" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.phonePrefix || this.state.phonePrefix === "") {
      this.setState({
        messageError: "Veuillez choisir l'indicateur de votre pays",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.phoneNumber || this.state.phoneNumber === "") {
      this.setState({
        messageError: "Veuillez saisir un numéro de téléphone ",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.email || this.state.email === "") {
      this.setState({ messageError: "Veuillez saisir votre email" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({
        messageError: "Veuillez saisir une adresse email valide",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }
    if (!this.state.projectRef || this.state.projectRef === "") {
      this.setState({ messageError: "Veuillez sélectionez un projet" });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    } else {
      await Projects.map((project) => {
        if (project.ref === this.state.projectRef) {
          this.setState({ project: project.title });
        }
      });
    }

    if (!this.state.refer || this.state.refer === "") {
      this.setState({
        messageError: "Comment vous nous avez connus ?",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    if (!this.state.condition || this.state.condition === false) {
      this.setState({
        messageError:
          "Veuillez accepter les conditions générales d'utilisation",
      });
      setTimeout(() => {
        this.setState({ messageError: "" });
      }, 2000);
      return false;
    }

    const infoProspect = {};
    infoProspect.state = this.state;
    if (sessionStorage.getItem("utms")) {
      infoProspect.utms = JSON.parse(sessionStorage.getItem("utms"));
    } else if (sessionStorage.getItem("refer")) {
      infoProspect.refer = sessionStorage.getItem("refer");
    }

    localStorage.setItem("prospect", JSON.stringify(infoProspect));
    this.props.history.push("/remerciements");
    this.state.is_visible = false;
    this.state.firstName = "";
    this.state.lastName = "";
    this.state.email = "";
    this.state.project = "";
    this.state.message = "";
    this.state.country = "Maroc";
    this.state.phonePrefix = "+212";
    this.state.phoneNumber = "";
    this.state.projectRef = "";
    this.state.refer = "";
    this.state.condition = false;
  }
  render() {
    $(document).ready(
      function () {
        if ($("body").hasClass("magic-house")) {
          this.setState({ projectRef: "CH28469" });
          console.log("magic-house");
        } else if ($("body").hasClass("garden-bay")) {
          this.setState({ projectRef: "CH28467" });
          console.log("garden-bay");
        } else if ($("body").hasClass("ricoflor-palmes")) {
          this.setState({ projectRef: "CH28470" });
        } else if ($("body").hasClass("the-sand-house")) {
          this.setState({ projectRef: "CH28471" });
          console.log("the-sand-house");
        } else if ($("body").hasClass("blue-waters-house")) {
          this.setState({ projectRef: "CH28465" });
        } else if ($("body").hasClass("les-perles-de-bentriaa")) {
          this.setState({ projectRef: "CH28468" });
        } else {
        }
      }.bind(this)
    );
    const { is_visible } = this.state;
    return (
      <div
        id="mobilecontact-section"
        class="row align-items-center"
        style={{ backgroundColor: "#fff" }}
      >
        <div class="row align-items-center" id="contact-section-mobile">
          <div class="col-md-7" id="form-sec">
            <h1>NOUS CONTACTER</h1>
            <form>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control inpt"
                  id="nom"
                  placeholder="Nom"
                  value={this.state.lastName}
                  onChange={this.handleLastName}
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control inpt"
                  id="Prenom"
                  placeholder="Prénom"
                  value={this.state.firstName}
                  onChange={this.handleFirstName}
                />
              </div>
              <div class="row">
                <div class="form-group col-5">
                  <select
                    id="inputCountry"
                    class="form-control inpt"
                    onChange={this.handleCountry}
                  >
                    {this.renderOption()}
                  </select>
                </div>
                <div class="form-group col-2" style={{ padding: 0 }}>
                  <input
                    type="text"
                    class="form-control inpt"
                    id="inputPhoneCode"
                    placeholder="+212"
                    value={this.state.phonePrefix}
                  />
                </div>
                <div class="form-group col-5">
                  <input
                    type="text"
                    class="form-control inpt"
                    id="inputPhone"
                    placeholder="Tel*"
                    value={this.state.phoneNumber}
                    onChange={this.handlePhone}
                  />
                </div>
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control inpt"
                  id="exampleInputEmail1"
                  placeholder="email@exemple.com"
                  value={this.state.email}
                  onChange={this.handleEmail}
                />
              </div>
              <div class="form-group">
                <select
                  id="inputProjectFooter"
                  class="form-control inpt"
                  onChange={this.handleProject}
                  value={this.state.projectRef}
                >
                  <option value="">Veuillez choisir votre projet*</option>
                  {this.renderOptionProjects()}
                </select>
              </div>
              <div class="form-group">
                <select
                  id="inputCountry"
                  class="form-control inpt"
                  onChange={this.handleRefer}
                  value={this.state.refer}
                >
                  <option value="">Comment vous nous avez connus ?*</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Bannière web">Bannière web</option>
                  <option value="Google">Google</option>
                  <option value="YouTube">YouTube</option>
                  <option value="Gmail">Gmail</option>
                  <option value="Mubawab">Mubawab</option>
                  <option value="Waze">Waze</option>
                  <option value="Bouche à oreille">Bouche à oreille</option>
                  <option value="Convention entreprise">
                    Convention entreprise
                  </option>
                  <option value="Passage bureau de vente">
                    Passage bureau de vente
                  </option>
                  <option value="Habillage véhicule">Habillage véhicule</option>
                  <option value="Affichage urbain ">Affichage urbain </option>
                  <option value="Brochure / flyer publicitaire">
                    Brochure / flyer publicitaire
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control inpt"
                  id="validationTextarea"
                  placeholder="Message"
                  value={this.state.message}
                  onChange={this.handleMessage}
                ></textarea>
              </div>
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input chkinpt"
                  id="exampleCheck1"
                  checked={this.state.condition}
                  onChange={this.handleCondition}
                />
                <label class="form-check-label" for="exampleCheck1">
                  J'ai lu et j'accepte{" "}
                  <a
                    style={{ color: "#b5a282" }}
                    href="/conditions-generales"
                    target="_blank"
                  >
                    les conditions générales d'utilisation{" "}
                  </a>
                  notamment la mention relative à{" "}
                  <a
                    style={{ color: "#b5a282" }}
                    href="/politique-de-confidentialite"
                    target="_blank"
                  >
                    la protection des données personnelles
                  </a>
                  .
                </label>
                <p>
                  Conformément à la loi 09-08, vous disposez d'un droit d'accès,
                  de rectification et d'opposition au traitement de vos données
                  personnelles. Ce traitement à été notifié à la CNDP au titre
                  du récépissé n°...
                </p>
              </div>

              {this.state.messageError !== "" ? (
                <div class="alert alert-danger">
                  <strong>{this.state.messageError}</strong>
                </div>
              ) : (
                <div></div>
              )}
              <div class="row align-items-end">
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <button
                  id="btn"
                  onClick={this.send.bind(this)}
                  class="col btn btn-primary"
                >
                  Envoyer
                </button>
              </div>
              <br />
            </form>
          </div>
        </div>

        <div id="maps_iframe" class="col-md-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.3456423788507!2d-7.6170564854126654!3d33.54439485176956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62d5ec31c657b%3A0xc2faabd2ae952464!2sCHAABANE%20IMMOBILIER!5e0!3m2!1sfr!2sma!4v1582146425589!5m2!1sfr!2sma"
            width="100%"
            height="463px"
            frameborder="0"
            style={{ border: "0" }}
            allowfullscreen=""
          ></iframe>
        </div>
        <div
          id="infocontact-text"
          class="col-12"
          style={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)),
           url(${image})`,
          }}
        >
          <h5>CHAABANE IMMOBILIER</h5>
          <div class="infocontactlp">
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> 7 Lotissement Ghita
              Avenue « N » Californie Casablanca Maroc
            </p>
            <div class="row">
              <div class="col-12">
                <img src={Logo} alt=""></img>
                <div class="gotomap">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="38.735"
                    width="33.749"
                  >
                    <g transform="translate(-18.121 -3.364)">
                      <rect
                        ry="4.928"
                        y="3.364"
                        x="18.121"
                        height="38.735"
                        width="33.749"
                        fill="transparent"
                      />
                      <g transform="translate(-.48 2.134)">
                        <rect
                          ry="4.928"
                          y="1.229"
                          x="18.601"
                          height="38.735"
                          width="33.749"
                          fill="url(#b)"
                        />
                        <g fill="#b7853e">
                          <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                          <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="col-9">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item info">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    Tél: <a href="tel:+212522211717">+212 522 21 17 17 </a>
                    <br /> Gsm:{" "}
                    <a href="tel:+212614989898">+212 614 98 98 98</a>
                  </li>
                  <li class="nav-item info">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <a href="mailto:contact@chaabaneinvest.com">
                      contact@chaabaneinvest.com
                    </a>
                  </li>
                  <li class="nav-item info">
                    <FontAwesomeIcon icon={faFax} />
                    Fax: <a href="tel:05 22 50 03 10">05 22 50 03 10</a>
                  </li>
                </ul>
              </div>
              <div class="col-3">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item info">
                    <a
                      class="social-media"
                      target="_blank"
                      href="https://www.instagram.com/chaabane.immobilier/?hl=fr"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li class="nav-item info">
                    {" "}
                    <a
                      class="social-media"
                      target="_blank"
                      href="https://fr-fr.facebook.com/Chaabaneimmobilier/"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                  <li class="nav-item info">
                    <a
                      class="social-media"
                      target="_blank"
                      href="https://ma.linkedin.com/in/chaabane-immobilier-196523120"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="mapcontact">
            <div class="backinfos">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="38.735"
                width="33.749"
              >
                <g transform="translate(-18.121 -3.364)">
                  <rect
                    ry="4.928"
                    y="3.364"
                    x="18.121"
                    height="38.735"
                    width="33.749"
                    fill="transparent"
                  />
                  <g transform="translate(-.48 2.134)">
                    <rect
                      ry="4.928"
                      y="1.229"
                      x="18.601"
                      height="38.735"
                      width="33.749"
                      fill="url(#b)"
                    />
                    <g fill="#b7853e">
                      <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                      <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.3456423788507!2d-7.6170564854126654!3d33.54439485176956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62d5ec31c657b%3A0xc2faabd2ae952464!2sCHAABANE%20IMMOBILIER!5e0!3m2!1sfr!2sma!4v1582146425589!5m2!1sfr!2sma"
              width="100%"
              height="300px"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen=""
            ></iframe>
          </div>
        </div>

        <div className="scroll-to-top">
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="38.735"
                width="33.749"
              >
                <g transform="translate(-18.121 -3.364)">
                  <rect
                    ry="4.928"
                    y="3.364"
                    x="18.121"
                    height="38.735"
                    width="33.749"
                    fill="transparent"
                  />
                  <g transform="translate(-.48 2.134)">
                    <rect
                      ry="4.928"
                      y="1.229"
                      x="18.601"
                      height="38.735"
                      width="33.749"
                      fill="url(#b)"
                    />
                    <g fill="#b7853e">
                      <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                      <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ContactMobile);
