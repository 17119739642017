import React, { Component } from "react";
import first from "../../Images/acceuilbg.jpg";
import MediaQuery from "react-responsive";
import firstMobile from '../../Images/Final-header.png';
import video from "../../videos/acceuilvideo.webm";

export class menu extends Component {

  render() {

    return (
      
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <div style={{ height: "158px", overflow: "hidden", marginBottom: "30px"}}> 
          <video width="100%" height="315" autoPlay loop muted defaultMuted playsinline style={{ backgroundColor: "black", marginBottom: "40px", marginTop: "-78px"}}>
            <source src={video} type="video/mp4" />
  </video>
  </div>
        {/* <img src={firstMobile} class="d-block w-100" alt="..." /> */}
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
         <div style={{ height: "595px", overflow: "hidden"}}> {/* desktop */}
          <video width="100%" height="765" autoPlay loop muted style={{ backgroundColor: "black" ,position:"relative",top:"-75px"}}>
            <source src={video} type="video/mp4" />
          </video>
</div> 
          {/* <img src={first} class="d-block w-100" alt="..." /> */}
        </MediaQuery>
      </div>
    );
  }
}

export default menu;
