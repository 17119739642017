import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Link as Goto } from "react-scroll";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import logo from "../../Images/logochaabane.png";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Contactpopup from "./Contact-popup";
import $ from "jquery";
import menu from "../../Images/menu.svg";

export class HeaderHomeMobile extends Component {
  componentDidMount() {
    $("img.menu").click(function () {
      if ($("#Header.mobile ul.ml-auto").hasClass("ishidden")) {
        $("#Header.mobile ul.ml-auto").animate({ right: "0" });
        $("nav#Header.mobile img.d-block").css({
          transform: "rotate(-90deg)",
          transition: "all 0.3s ease-in-out",
        });
        $("#Header.mobile ul.ml-auto").removeClass("ishidden");
      } else {
        $("#Header.mobile ul.ml-auto").animate({ right: "-75%" });
        $("nav#Header.mobile img.d-block").css({
          transform: "rotate(0deg)",
          transition: "all 0.3s ease-in-out",
        });
        $("#Header.mobile ul.ml-auto").addClass("ishidden");
      }
    });
  }

  render() {
    return (
      <div>
        <nav id="Header" class="navbar navbar-expand-lg home-header mobile">
          <img src={menu} class="d-block menu" alt="..." />
          <ul class="navbar-nav" style={{right:"55px",position:"absolute"}}>
              <li class="nav-item info">
                <FontAwesomeIcon icon={faPhoneAlt} style={{width: "18px",height: "18px",padding: "4px"}} />
                <a href="tel:+212522211717" style={{fontSize:"8px"}}>+212 522 21 17 17</a> / <a style={{fontSize:"8px"}} href="tel:+212614989898">+212
                614 98 98 98</a>
              </li>
            </ul>
          <ul class="navbar-nav ml-auto ishidden">
            <li class="item">
              <Link class="link" to="/">
                ACCEUIL
              </Link>
            </li>
            <li class="item">
              <Link class="link" to="/apropos">
                A PROPOS
              </Link>
            </li>
            <li class="item dropdown">
              <Link
                class="link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                NOS PROJETS
              </Link>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="/Project/AnfaBloom">
                  Anfa Bloom
                </a>
                <a class="dropdown-item" href="/Project/16eme-angle">
                  Le 16 eme Angle
                </a>
                <a class="dropdown-item" href="/Project/the-sand-house">
                  The Sand House
                </a>
                <a class="dropdown-item" href="/Project/blue-waters-house">
                  Blue Waters House
                </a>
                <a class="dropdown-item" href="/Project/magic-house-beach">
                  Magic House Beach
                </a>
                <a class="dropdown-item" href="/Project/les-perles-de-bentriaa">
                  Les Perles de Bentriaa II
                </a>
                <a class="dropdown-item" href="/Project/magic-house">
                  Magic House
                </a>
                <a class="dropdown-item" href="/Project/garden-bay">
                  Garden Bay
                </a>
                <a class="dropdown-item" href="/Project/ricoflores-palm">
                  Ricoflores Palm
                </a>
              </div>
            </li>
            <li class="item">
              <Link class="link" to="/actualites">
                ACTUALITÉS
              </Link>
            </li>
            <li class="item">
              <Link class="link" to="/carriere">
                CARRIÈRE
              </Link>
            </li>
            <li class="item">
              <Link class="link" to="/contact">
                CONTACT
              </Link>
            </li>
            <li class="link social">
              <a class="social-media" href="https://www.facebook.com/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a class="social-media" href="https://www.linkedin.com/">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a class="social-media" href="https://www.instagram.com/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
          </ul>
        </nav>
        <nav id="Header2" class="navbar navbar-expand-lg home-header mobile">
          <img src={logo} class="d-block" alt="..." />
          <ul class="navbar-nav ml-auto">
            <li class="item">
              <span class="contact-popup-btn">NOUS CONTACTER</span>
            </li>
          </ul>
        </nav>
        <div class="contact-popup hidden home-header">
          <Contactpopup />
        </div>
      </div>
    );
  }
}

export default HeaderHomeMobile;
